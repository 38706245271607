import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from '../layout/app-layout/app-layout.component';
import { HomeLayoutComponent } from '../layout/home-layout/home-layout.component';
import { BrandResolverService } from '../brand/brand-resolver.service';
import { NewstickerResolverService } from '../shared/newsticker/newsticker-resolver.service';
import { ProductgroupResolverService } from '../productgroup/productgroup-resolver.service';
import { BlockResolverService } from '../shared/cms/block-resolver.service';
import { ProductResolverService } from '../product/product-resolver.service';
import { HomepageComponent } from './homepage/homepage.component';
import { BrandsComponent } from './brands/brands.component';
import { BrandComponent } from './brand/brand.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { NewsComponent } from './news/news.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { LandingComponent } from '../product/landing/landing.component';
import { LandingResolverService } from '../product/landing-resolver.service';
import { AuthBrandResolverService } from '../auth/auth-brand-resolver.service';
import { ComplianceComponent } from './compliance/compliance.component';

const PagesRoutes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { 
        path: '',
        //redirectTo: '/home',
        component: HomepageComponent,
        resolve: [BrandResolverService, NewstickerResolverService, ProductgroupResolverService, BlockResolverService],
        data: {
          pageTitle: 'wiesel DEFENCE',
          animation: ''
        },
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomepageComponent,
        resolve: [BrandResolverService, NewstickerResolverService, ProductgroupResolverService, BlockResolverService],
        data: {
          pageTitle: 'wiesel DEFENCE',
          animation: ''
        }
      },
      {
        path: 'brands',
        component: BrandsComponent,
        resolve: [BrandResolverService, BlockResolverService],
        data: {
          pageTitle: 'Wir führen Produkte von weltklasse Hersteller für Ihre Sicherheit',
          animation: ''
        }
      },
      {
        path: 'brand/:brand',
        component: BrandComponent,
        resolve: [BrandResolverService, BlockResolverService, ProductResolverService, AuthBrandResolverService],
        data: {
          pageTitle: 'Wir führen Produkte von weltklasse Hersteller für Ihre Sicherheit',
          animation: ''
        }
      },
      {
        path: 'special/product/:landing',
        component: LandingComponent,
        resolve: [LandingResolverService],
        data: {
          pageTitle: 'Wir führen Produkte von weltklasse Hersteller für Ihre Sicherheit',
          animation: ''
        }
      },
      {
        path: 'imprint',
        component: ImprintComponent,
        resolve: [BlockResolverService],
        data: {
          pageTitle: 'Impressum',
          animation: ''
        }
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
        resolve: [BlockResolverService],
        data: {
          pageTitle: 'Datenschutz',
          animation: ''
        }
      },
      {
        path: 'news',
        component: NewsComponent,
        resolve: [BlockResolverService, NewstickerResolverService],
        data: {
          pageTitle: 'Produktvorstellungen / Messen / Presse',
          animation: ''
        }
      },
      {
        path: 'news/:news',
        component: NewsComponent,
        resolve: [BlockResolverService, NewstickerResolverService],
        data: {
          pageTitle: 'Produktvorstellungen / Messen / Presse',
          animation: ''
        }
      },
      {
        path: 'about-wiesel-defence',
        component: AboutComponent,
        resolve: [BlockResolverService],
        data: {
          pageTitle: 'Über uns',
          animation: ''
        }
      },
      {
        path: 'compliance',
        component: ComplianceComponent,
        resolve: [BlockResolverService],
        data: {
          pageTitle: 'Compliance',
          animation: ''
        }
      },
      {
        path: 'contact',
        component: ContactComponent,
        resolve: [BlockResolverService],
        data: {
          pageTitle: 'Kontakt',
          animation: ''
        }
      }
    ],
    data: {
      animation: 'App',
      pageTitle: 'wiesel DEFENCE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(PagesRoutes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
