import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, map, switchMap } from 'rxjs/operators';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromApp from '../store/app.reducer';

@Injectable({ providedIn: 'root' })
export class AuthResolverService implements Resolve<any> {
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let storedUser = localStorage.getItem('userData');
    if(storedUser) {
      return of(storedUser);
    }
  }
}
