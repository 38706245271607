import {
  animateChild,
  trigger,
  transition,
  query,
  style,
  group,
  animate
} from '@angular/animations';

const slideViewAnimation = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0
    })
  ]),
  query(':enter', [style({ left: '-100%' })]),
  query(':leave', animateChild()),
  group([
    query(':leave', [animate('1200ms ease-out', style({ left: '100%' }))]),
    query(':enter', [animate('1200ms ease-out', style({ left: '0%' }))])
  ]),
  query(':enter', animateChild())
];

export const routeSlideAnimation = trigger('routeSlideAnimation', [
  transition('Introduction <=> DataBurn', slideViewAnimation),
  transition('DataBurn <=> Extraction', slideViewAnimation)
]);
