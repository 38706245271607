import { Component, OnInit } from '@angular/core';
import { authAnimations } from './auth.animations';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  animations: [authAnimations]
})
export class AuthLayoutComponent implements OnInit {
  breakpoint;

  constructor() {}

  ngOnInit() {
    // calculate the number of columns for the layout
    this.breakpoint = window.innerWidth <= 400 ? 1 : 2;
  }

  onResize(event) {
    // calculate the number of columns for the layout
    this.breakpoint = event.target.innerWidth <= 400 ? 1 : 2;
  }
}
