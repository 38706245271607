import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { Newsticker } from '../../shared/newsticker/newsticker.model';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
  showDetailNews: boolean = false;
  newsItems: Newsticker[] = [];
  selectedNews: Newsticker;
  newstickerSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private router: Router
  ) { }

  ngOnInit() {
    const newsItemId = this.route.snapshot.params['news'];

    // load for all news
    this.newstickerSubscription = this.store.select('newsticker').subscribe((state) => {
      this.newsItems = state.newstickers;

      if(newsItemId) {
        // show a single item
        this.showDetailNews = true;
        this.selectedNews = this.newsItems.filter((item, index) => item.id == newsItemId).shift();
      }
    });
  }

  /**
   * redirect to the
   * given news detail page
   */
  showNews(news: Newsticker) {
    this.showDetailNews = true;
    this.router.navigate(['/news', news.id]);
  }

  showList() {
    this.showDetailNews = false;
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    if(this.newstickerSubscription) {
      this.newstickerSubscription.unsubscribe();
    }
  }

}
