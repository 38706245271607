import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from './notification.service';
import { Message } from './message.model';
import { environment } from '../../../environments/environment';
import { messageAnimationOut, messageAnimationIn } from './message.animations';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss'],
  animations: [messageAnimationOut, messageAnimationIn]
})
export class ErrorHandlerComponent implements OnInit, OnDestroy {
  private messagesSubscriber;
  messages: Message[] = [];

  constructor(private notificationService: NotificationService) {
    this.messagesSubscriber = this.notificationService.messagesChanged.subscribe(
      messages => {
        this.messages = messages;
      }
    );
  }

  ngOnInit() {}

  canEnableCloseButton() {
    return environment.messageNotification.enableManualDismiss;
  }

  dismissMessage(index) {
    this.notificationService.dismissMessage(index);
  }

  ngOnDestroy() {
    this.messagesSubscriber.unsubscribe();
  }
}
