import { ActionReducerMap } from '@ngrx/store';

import * as fromBrand from '../brand/store/brand.reducer';
import * as fromProduct from '../product/store/product.reducer';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromContactForm from '../shared/contact-form/store/contact-form.reducer';
import * as fromNewsticker from '../shared/newsticker/store/newsticker.reducer';
import * as fromProductgroup from '../productgroup/store/productgroup.reducer';
import * as fromBlock from '../shared/cms/store/block.reducer';
import * as fromLayout from '../layout/store/layout.reducer';
import * as fromOrder from '../order/store/order.reducer';
import * as fromNewsletter from '../shared/newsletter-subscribe/store/newsletter.reducer';
import * as fromFile from '../file/store/file.reducer';

export interface AppState {
  brand: fromBrand.State;
  product: fromProduct.State;
  auth: fromAuth.State;
  contact: fromContactForm.State;
  layout: fromLayout.State;
  newsticker: fromNewsticker.State;
  productgroup: fromProductgroup.State;
  block: fromBlock.State;
  order: fromOrder.State;
  newsletter: fromNewsletter.State;
  file: fromFile.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  brand: fromBrand.brandReducer,
  product: fromProduct.productReducer,
  auth: fromAuth.authReducer,
  contact: fromContactForm.contactFormReducer,
  layout: fromLayout.fromLayoutReducer,
  newsticker: fromNewsticker.newstickerReducer,
  productgroup: fromProductgroup.productgroupReducer,
  block: fromBlock.blockReducer,
  order: fromOrder.orderReducer,
  newsletter: fromNewsletter.newsletterReducer,
  file: fromFile.fileReducer
};
