import {
  trigger,
  transition,
  style,
  state,
  animate
} from '@angular/animations';

export const messageAnimationOut = trigger('flyOut', [
  state('in', style({ transform: 'translateX(0)' })),
  transition('* => void', [
    animate('100ms ease-in', style({ transform: 'translateX(50%)' }))
  ])
]);
export const messageAnimationIn = trigger('pushin', [
  transition('void => *', [
    style({ transform: 'translateY(-100%)', height: '*' }),
    animate('200ms ease-out', style({ transform: 'translateY(0)' }))
  ])
]);
