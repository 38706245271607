import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as FileActions from './file.actions';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { DirectusService } from '../../core/directus.service';
import * as fromApp from '../../store/app.reducer';
import { File } from '../file.model';

@Injectable()
export class FileEffects {
  
  uploadFile = createEffect(() => this.actions$.pipe(
    ofType(FileActions.UPLOAD_FILE),
    switchMap((uploadFileAction: FileActions.UploadFile) => {
      const client = this.directus.getClient();
      let payload = uploadFileAction.payload;
      if(!payload) {
        // skip if no payload (file)
        return of(new FileActions.FileUploaded({}));
      }

      const file = this.DataURIToBlob(payload.data)
      const formData = new FormData();

      /**
       * @todo make this folder id env var
       */
      formData.append('folder', '026b1270-d8cd-4a44-8f3e-64d8c8d97465');
      formData.append('file', file, payload.filename) 
      const fileId = client.files.createOne(formData, );

      // return from(client.transport.post('/files', payload)).pipe(
      return from(fileId).pipe(
        map(result => {
          // dispatch action to set contact as received
          return new FileActions.FileUploaded(result);
        }),
        catchError(error => {
          console.log(error)
          return of(new FileActions.FileUploadFailed([error.message]));
        })
      );
    })
  ));

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>) {}
}