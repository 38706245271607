import {
  trigger,
  transition,
  style,
  state,
  animate
} from '@angular/animations';

export const authAnimations = trigger('intro', [
  state(
    'introduce',
    style({
      opacity: 1,
      transform: 'scale(1)'
    })
  ),
  transition('void => *', [
    style({
      opacity: 0.5,
      transform: 'scale(0.9)'
    }),
    animate(300)
  ])
]);
