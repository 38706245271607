import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TweenMax, TimelineLite, Power2 } from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import * as ScrollMagic from 'scrollmagic';
import 'jquery.waitforimages';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromAppReducer from '../../store/app.reducer';

@Directive({
  selector: '[appAnimation]'
})
export class AnimationDirective implements OnChanges, OnInit {
  layoutSubscription: Subscription;
  scrollbar: Scrollbar;
  scene;

  /**
   * directive to animate
   * items by fading them in
   */
  hasAnimation() {
    // animate each
    const controller = new ScrollMagic.Controller();
    const $this = $(this.elementRef.nativeElement);
    const $thisHeight = $(this.elementRef.nativeElement).height() ? Math.abs($(this.elementRef.nativeElement).height()) : 100;
    
    

    if($this[0] !== undefined) {
      this.scene = new ScrollMagic.Scene({triggerElement: $this[0], duration: $thisHeight})
        .addTo(controller);
      
        this.scene.triggerHook(1);
      
        this.scene.on('enter', function(){
        $this.delay(parseInt($this.attr('data-delay'))).queue((next) => {
          TweenMax.to($this, 0.6, {force3D:true, opacity:1, y:0, scale:1, delay:0.1, ease:Power2.easeOut});
          next();
        });
      });
      
      this.scene.on('leave', function(event){
        $this.removeClass('active');
      });
      
      if(this.scrollbar) {
        this.scrollbar.addListener(() => {
          this.scene.refresh();
        });
      }
    }
  }

  ngOnChanges() {
    if(this.scene) {
      this.scene.refresh();
    }
  }

  ngOnInit() {
    this.layoutSubscription = this.store.select('layout').subscribe(state => {
      if(state) {
        this.scrollbar = state.scrollbar;
        this.hasAnimation();
      }
    });
  }

  ngOnDestroy() {
    if(this.layoutSubscription) {
      this.layoutSubscription.unsubscribe();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private store: Store<fromAppReducer.AppState>,
    private router: Router
  ) {
    // force directive not to reuse for route
    // if not set to false, animations stop working
    // after clicking around
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}
