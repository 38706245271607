import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take } from 'rxjs/operators';
import { Productgroup } from '../productgroup.model';
import { FileImageModifier, HelperService } from 'src/app/file/helper.service';

@Component({
  selector: 'app-productgroup-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  groups: Productgroup[];

  constructor(private store: Store<fromApp.AppState>, public fileHelper: HelperService) { }

  ngOnInit() {
    this.store.select('productgroup').pipe(take(1)).subscribe(store => {
      this.groups = store.productgroups;
    });
  }

  getImage(image) {
    return this.fileHelper.getImage(image, {
      fit: "cover",
      width: 490,
      height: 326,
      quality: 70
    } as FileImageModifier);
  }
}
