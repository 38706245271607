import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { TweenMax, TimelineLite, Power2, Power3, Power4 } from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import * as Swiper from 'swiper';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromAppReducer from '../../store/app.reducer';

@Directive({
  selector: '[appHero]'
})
export class HeroDirective implements OnChanges {
  layoutSubscription: Subscription;
  scrollbar: Scrollbar;
  @Input('appHero') heroSliderElement: ElementRef;

  ngOnChanges() {
  }

  constructor(
    private elementRef: ElementRef,
    private store: Store<fromAppReducer.AppState>
  ) {
    
  }

  ngOnInit() {
    this.layoutSubscription = this.store.select('layout').subscribe(state => {
      if(state) {
        this.scrollbar = state.scrollbar;
        this.initHero();
      }
    });
  }

  ngOnDestroy() {
    if(this.layoutSubscription) {
      this.layoutSubscription.unsubscribe();
    }
  }

  initHero() {
    const self = this;
		
    if ($("#page-content").hasClass("light-content")) {
        $("main, nav").css('background-color', '#000');
        $('#magic-cursor').addClass('light-content');
        if( $('#hero').length > 0 ){						
            if( $('#hero').hasClass("has-image")) {	
                $("header").css('background-color', 'transparent');
            } else {
                $("header").css('background-color', 'transparent');
            }
        } else {
            $("header").css('background-color', 'transparent');
        }
    } else {
        $("main").css('background-color', '#fff');
        $("nav").css('background-color', '#0f1010');
        $('#magic-cursor').removeClass('light-content');
        if( $('#hero').length > 0 ){	
            if( $('#hero').hasClass("has-image")) {	
                $("header").css('background-color', 'transparent');
            } else {
                $("header").css('background-color', 'transparent');
            }
        } else {
            $("header").css('background-color', 'transparent');
        }
    }

    if ($("body").hasClass("smooth-scroll")) {
        var elem = document.querySelector("#content-scroll") as HTMLElement;
        var scrollbar = Scrollbar.init(elem,
        {
            renderByPixels: true,
            damping:1
        });
    }
    
    // $('body').waitForImages({
    //     finished: function() {
    //         $('body').removeClass('loading')
    //         setTimeout( function(){	
    //             $('body').removeClass('hidden').removeClass('scale-up').removeClass('scale-none');
    //         } , 1500 );
    //     },
    //     waitForAll: true
    // });	
    
    // $('body').waitForImages({
    //     finished: function() {
    //         TweenMax.to($("#header-container"), 1, {force3D:true, opacity:1, ease:Power2.easeOut});				
    //     },
    //     waitForAll: true
    // });
    
    TweenMax.to($("#main"), 0.2, {force3D:true, opacity:1, delay:0.1, ease:Power2.easeOut});
            
    var navtitleheight = $(".hero-title").height()
    var navsubtitleheight = $(".hero-subtitle").height()
    
    if( $('#hero').hasClass("has-image")) {	
        if( $('body').hasClass("load-project-thumb")) {
            TweenMax.to($("#hero-bg-image"), 0, {force3D:true, scale:1.05 , opacity:1, delay:0, ease:Power2.easeOut});
            TweenMax.to($(".hero-title"), 0.4, {force3D:true, y:-navtitleheight, opacity:1, delay:0.5, ease:Power2.easeOut});
            TweenMax.to($(".hero-subtitle"), 0.4, {force3D:true, y:navsubtitleheight, opacity:1, delay:0.6, ease:Power2.easeOut});
        } else {
            TweenMax.to($("#hero-bg-image"), 0, {force3D:true, scale:1.05 , opacity:1, delay:0, ease:Power2.easeOut});
            TweenMax.to($(".hero-title"), 0, {force3D:true, y:-navtitleheight, opacity:1, delay:0, ease:Power2.easeOut});
            TweenMax.to($(".hero-subtitle"), 0, {force3D:true, y:navsubtitleheight, opacity:1, delay:0, ease:Power2.easeOut});
        }
        TweenMax.to($(".scroll-down-wrap"), 0.4, {force3D:true, y: 0, opacity:1, delay:0.85, ease:Power2.easeOut});
        TweenMax.to($("#main-page-content"), 0.4, {force3D:true, opacity:1, y:0, delay:0.95, ease:Power2.easeOut});
    } else {
        TweenMax.to($(".hero-title"), 0.4, {force3D:true, y: -navsubtitleheight, opacity:1, delay:0.1, ease:Power2.easeOut});
        TweenMax.to($(".hero-subtitle"), 0.4, {force3D:true, y: navtitleheight, opacity:1, delay:0.15, ease:Power2.easeOut});
        TweenMax.to($(".post-article-wrap"), 0.4, {force3D:true, y: 0, opacity:1, ease:Power2.easeOut});
        TweenMax.to($("#main-page-content"), 0.4, {force3D:true, opacity:1, y:0, delay:0.15, ease:Power2.easeOut});			
    }		
    
    if ($('#hero-bg-image').hasClass("light-content")) {
        $('#hero-caption').addClass('light-content');
        setTimeout(function(){
            $('#magic-cursor').addClass('light-content');
        } , 700 );			
        setTimeout(function(){
            $('#header-container').addClass('light-content');
        } , 600 );
    }
    
		// Fading In Small Carousel elements on Finised
		var tlCarousel = new TimelineLite();
		tlCarousel.set($("#showcase-carousel .swiper-slide"), {x: 300, opacity:0});
		$("#showcase-carousel .swiper-slide").each(function(index, element) {
			tlCarousel.to(element, 1.4, {x:0, opacity:1, delay:0.7, ease:Power3.easeOut}, index * 0.1)
		});
		// Fading In Large Carousel elements on Finised
		var tlCarousel = new TimelineLite();
		tlCarousel.set($("#large-showcase-carousel .swiper-slide"), {x: 300, opacity:0});
		$("#large-showcase-carousel .swiper-slide").each(function(index, element) {
			tlCarousel.to(element, 1.4, {x:0, opacity:1, delay:0.7, ease:Power3.easeOut}, index * 0.1)
		});
		TweenMax.set($(".swiper-scrollbar"), {scaleX: 0,});
		TweenMax.to($(".swiper-scrollbar"), 1.2, {force3D:true, scaleX: 1, delay:0.7, ease:Power2.easeOut});
		
		TweenMax.to($("#footer-container"), 1, {force3D:true, opacity:1, delay:0.4, ease:Power2.easeOut});		
		
		if( $('.load-project-thumb').length > 0 ){
			setTimeout( function(){
				$('#hero-bg-wrapper').find('video').each(function() {
				});
				$('.thumb-container').remove();
				if( $('#project-nav').length > 0 ){
					TweenMax.to(scrollbar, 1.5, {scrollTo:180, delay:0.6, ease:Power4.easeInOut});
				}
			} , 200 );
		} else {
			$('#hero-bg-wrapper').find('video').each(function() {
			});
			if( $('#project-nav').length > 0 ){
				if ($("body").hasClass("smooth-scroll")) {
					TweenMax.to(scrollbar, 1.5, {scrollTo:180, delay:0.3, ease:Power4.easeInOut});
				} else {                    
					TweenMax.to(window, 1.5, {scrollTo:180, delay:0.3, ease:Power4.easeInOut});           
				}				
			}
		}
		
		setTimeout( function(){	
			$('body').removeClass("load-project-thumb").removeClass("load-project-page").removeClass("load-next-project").removeClass("show-loader").removeClass("load-next-page");
		} , 800 );
		
		setTimeout( function(){				
			$('#showcase-holder').removeClass("disabled");
		} , 1900 );
		
	
		if ($('body').hasClass('mute')) {
			$(".icon-wrap").addClass("disabled");
			$('.button-text span').text($('.button-text span').data('off'));
			$('.button-text span').attr("data-off", $('.button-text span').data('on'));
			$('.button-text span').attr("data-on", $('.button-text span').text());
		} else {
			$('.button-text span').text($('.button-text span').data('on'));
			$('.button-text span').attr("data-on", $('.button-text span').data('on'));
			$('.button-text span').attr("data-off", $('.button-text span').data('off'));
		}
		
  }

  linesWidth() {
    let lineWidth;
    var carouselWidth = $('#showcase-holder').width();
    var captionWidth = $('.swiper-pagination-bullet-active .title').width();
    if ($(window).width() >= 1466) {
        lineWidth = carouselWidth / 2 - 440
    } else if ($(window).width() >= 1024) {
        lineWidth = carouselWidth / 2 - 220
    } else if ($(window).width() >= 767) {
        lineWidth = carouselWidth / 2 - 160
    } else if ($(window).width() >= 479) {
        lineWidth = carouselWidth / 2 - 50
    } else {
        lineWidth = carouselWidth / 2 - 40
    }
            
    $(".caption-border.left").css({
      'width': lineWidth - captionWidth/2 + 'px',
      'opacity': 1,
    });
    $(".caption-border.right").css({
      'width': lineWidth - captionWidth/2 + 'px',
      'opacity': 1,
    });
  }
}
