import * as BrandActions from './brand.actions';
import { Brand } from '../brand.model';

export interface State {
  brandOptions: [];
  brands: Brand[];
  editingBrandId: number;
  editingBrand: Brand;
}

const initialState: State = {
  brandOptions: [],
  brands: [],
  editingBrandId: -1,
  editingBrand: null
};

export function brandReducer(
  state: State = initialState,
  action: BrandActions.BrandActions
) {
  switch (action.type) {
    case BrandActions.SET_BRANDS:
      return {
        ...state,
        brands: action.payload.brands
      };
    case BrandActions.ADD_BRAND:
      return {
        ...state,
        brands: [...state.brands, action.payload]
      };
    case BrandActions.UPDATE_BRAND:
      const brand = state.brands[action.payload.index];
      const updatedBrand = {
        ...brand,
        ...action.payload.brand
      };
      const updatedBrands = [...state.brands];
      updatedBrands[action.payload.index] = updatedBrand;

      return {
        ...state,
        brands: updatedBrands,
        editingBrandId: -1,
        editingBrand: null
      };
    case BrandActions.START_EDIT:
      return {
        ...state,
        editingBrandId: action.payload,
        // we must create a NEW object NO REFERENCE! so copy all props to a new object
        editingBrand: { ...state.brands[action.payload] }
      };
    case BrandActions.STOP_EDIT:
      return {
        ...state,
        editingBrandId: -1,
        editingBrand: null
      };

    default:
      return state;
  }
}
