import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import * as fromAuth from '../store/auth.reducer';
import * as AuthActions from '../store/auth.actions';
import { UntypedFormControl, Validators, UntypedFormGroup, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { environment } from '../../../environments/environment';


const matchValues = function(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  @ViewChild('registerForm', { static: false }) registerForm;
  successfullSent = false;
  loading = false;
  registerFormR = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    firstname: new UntypedFormControl('', [Validators.required]),
    lastname: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+/)]),
    passwordConfirm: new UntypedFormControl('', [Validators.required, matchValues('password')]),
  });

  constructor(
    private store: Store<fromApp.AppState>,
    private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.authSubscription = this.store.select('auth').subscribe((state) => {
      if(this.loading === true
        && (state.authReqSent || state.authError)) {
        this.loading = false;
        this.successfullSent = true;
      }
    });
  }

  ngOnDestroy() {
    if(this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  register() {
    const registerForm = this.registerFormR;
    this.loading = true;

    /**
     * check if email already exists
     */
    fetch(environment.api.url + "/check?email=" + registerForm.controls.email.value).then(async (response) => {
      const json = await response.json();
      if(json.exists) {
        // email is new, so continue
        this.store.dispatch(
          new AuthActions.SignupStart({
            email: registerForm.controls.email.value,
            firstname: registerForm.controls.firstname.value,
            lastname: registerForm.controls.lastname.value,
            password: registerForm.controls.password.value,
            tags: []
          })
        );
      } else {
        // email exists - throw message
        this.snackBar.open("Die E-Mail Adresse ist bereits registriert. Falls diese bereits geprüft und bestätigt wurde, gehen Sie zum Login. Bei weiteren Problemen, wenden Sie sich an contact@wiesel-defence.com.", 'Schließen');
        this.loading = false;
      }
    });
  }
}
