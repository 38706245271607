import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromStore from '../../store/app.reducer';
import * as NewsletterActions from './store/newsletter.actions';
import * as fromNewsletter from './store/newsletter.reducer';
import { NewsletterSubscription } from './newsletter-subscription.model';


@Component({
  selector: 'app-newsletter-subscribe',
  templateUrl: './newsletter-subscribe.component.html',
  styleUrls: ['./newsletter-subscribe.component.scss']
})
export class NewsletterSubscribeComponent implements OnInit, OnDestroy {
  newsletterResultSubscription: Subscription;
  subscribeform = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required])
  });

  subscriptionSending = false;
  subscriptionSentSuccessful = false;
  subscriptionErrors = [];

  constructor(
    private store: Store<fromStore.AppState>
  ) { }

  ngOnInit() {
    // wait for subscription actions to trigger
    this.newsletterResultSubscription = this.store.select('newsletter').subscribe((state: fromNewsletter.State) => {
      this.subscriptionSending = state.newsletterSubscriptionSubmitted;
      this.subscriptionSentSuccessful = state.newsletterSubscriptionSent && !state.newsletterSubscriptionFailed.length;
      this.subscriptionErrors = state.newsletterSubscriptionFailed;
    });
  }

  submitContact(event) {
    let newsletterSubscriber = new NewsletterSubscription();

    newsletterSubscriber.recipient = {
      email: this.subscribeform.controls.email.value,
      first_name: this.subscribeform.controls.firstName.value,
      last_name: this.subscribeform.controls.lastName.value
    }
    this.store.dispatch(new NewsletterActions.SubmitNewsletterSubscription({ newsletterSubscription: newsletterSubscriber }));
  }

  ngOnDestroy() {
    if(this.newsletterResultSubscription) {
      this.newsletterResultSubscription.unsubscribe();
    }
  }
}
