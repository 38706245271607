import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  /**
   * this filter will filter the "value"
   * (which needs to be an array of objects) by the
   * properties in "filterByProps" with the string "filterString"
   */
  transform(value: any, filterByProps: string[], filterString: string, ...args: any[]): any {
    if(value instanceof Array && filterString !== '') {
      return value.filter((item, index) => {
        let hasSearchKey = false;

        filterByProps.forEach(property => {
          if(item[property] !== undefined && item[property] !== null) {
            if(item[property].toLowerCase().indexOf(filterString.toLowerCase()) !== -1) {
              hasSearchKey = true;
            }
          }
        });

        return hasSearchKey;
      })
    }
    return value;
  }

}
