import * as NewstickerActions from './newsticker.actions';
import { Newsticker } from '../newsticker.model';

export interface State {
  newstickers: Newsticker[];
}

export const initialState: State = {
  newstickers: []
}

export function newstickerReducer(
  state: State = initialState,
  action: NewstickerActions.NewstickerActions
) {
  switch(action.type) {
    case NewstickerActions.SET_NEWSTICKERS:
      return {
        ...state,
        newstickers: action.payload.newstickers
      };
    default:
      return state;
  }
}