import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { Message } from './message.model';
import { ErrorType } from './error-type.enum';
import { environment } from '../../../environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public messagesChanged: BehaviorSubject<Message[]> = new BehaviorSubject<
    Message[]
  >([]);
  private messages: Message[] = [];

  constructor(private snackBar: MatSnackBar) {
  }

  private addMessage(message: Message) {
    // add messages as snackbar
    message.snackbarRef = this.snackBar.open(message.message, 'Schließen');

    this.messagesChanged.next(this.messages);
  }

  /**
   * manual dismiss message
   * function e.g. by index for
   * click event
   */
  dismissMessage(index) {
    this.messages.splice(index, 1);
    this.messagesChanged.next(this.messages);
  }

  showSuccess(title: string, message: string, link?: string): void {
    const messageModel: Message = new Message(
      ErrorType.success,
      title,
      message,
      link,
      new Date()
    );
    this.addMessage(messageModel);
  }

  showError(title: string, message: string, link?: string): void {
    const messageModel: Message = new Message(
      ErrorType.error,
      title,
      message,
      link,
      new Date()
    );
    this.addMessage(messageModel);
  }

  showWarning(title: string, message: string, link?: string): void {
    const messageModel: Message = new Message(
      ErrorType.warning,
      title,
      message,
      link,
      new Date()
    );
    this.addMessage(messageModel);
  }

  showInfo(title: string, message: string, link?: string): void {
    const messageModel: Message = new Message(
      ErrorType.info,
      title,
      message,
      link,
      new Date()
    );
    this.addMessage(messageModel);
  }

  getMessages() {
    return this.messages;
  }

  clearMessages() {
    this.messages = [];
    this.messagesChanged.next([]);
  }
}
