export interface CookieItem {
  name: string;
  vendor: string;
  purpose: string;
  expires: string;
  type: string;
}
export interface CookieInformation {
  required: CookieItem[];
  marketing: CookieItem[];
  statistic: CookieItem[];
}

export class ConfigurationModel {
  public usedCookies: CookieInformation = {
    required: [
    ],
    marketing: [],
    statistic: [
      {
        name: '_ga',
        vendor: 'Google',
        purpose: 'Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der Besucher die Website nutzt, zu generieren.',
        expires: '2 Jahre',
        type: 'HTTP'
      }]
  };
  public cookieInformationText = `Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten.
    Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre Erlaubnis.
    Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen.
    Sie können Ihre Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern oder widerrufen.
    Erfahren Sie in unserer Datenschutzrichtlinie mehr darüber, wer wir sind, wie Sie uns kontaktieren können und wie wir personenbezogene Daten verarbeiten.`;
}