import { Auth } from '../auth.model';
import { User } from '../../user/user.model';
import * as AuthActions from './auth.actions';

export interface State {
  auth: Auth;
  loading: boolean;
  authError: string;
  user: User;
  authReqSent: boolean;
  resetPassReqSent: boolean;
}

const initialState: State = {
  auth: null,
  loading: false,
  authError: null,
  user: null,
  authReqSent: false,
  resetPassReqSent: false
};

export function authReducer(
  state = initialState,
  action: AuthActions.AuthActions
) {
  switch (action.type) {
    case AuthActions.AUTHENTICATE_SUCCESS:
      const auth = new Auth(
        action.payload.token,
        action.payload.expirationDate,
        action.payload.refresh_token
      );
      return {
        ...state,
        auth,
        authError: null,
        loading: false
      };
    case AuthActions.LOGIN_START:
    case AuthActions.SIGNUP_START:
      return {
        ...state,
        authError: null,
        loading: true
      };
    case AuthActions.AUTHENTICATE_SENT:
      return {
        ...state,
        authReqSent: true
      };
    case AuthActions.AUTHENTICATE_FAIL:
      return {
        ...state,
        authError: action.payload.message,
        loading: false,
        user: null
      };
    case AuthActions.SEND_RESETPASSRESETREQ:
      return { 
        ...state,
        resetPassReqSent: true
      };
    case AuthActions.CLEAR_ERROR:
      return {
        ...state,
        authError: null
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        authError: null,
        auth: null,
        loading: false,
        user: null
      };
    case AuthActions.FETCH_USER:
      return {
        ...state,
        user: {...action.payload.user}
      }
    case AuthActions.SET_USER:
      return {
        ...state,
        user: {...action.payload.user}
      }
    default:
      return state;
  }
}
