import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Block } from '../../shared/cms/block.model';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';
import { Brand } from '../../brand/brand.model';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { Product } from '../../product/product.model';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { Landing } from '../landing.model';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from 'src/app/file/helper.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  landing: Landing;
  brandProducts: Product[];
  currentBrand: Brand;
  headerTitle: string = '';
  headerSubtitle: string = '';
  listItemProducts: Product[];
  displayedColumnsListItem: string[] = ['title', 'desc', 'actions'];

  // define the used sections
  chemThreatSec: any;
  videoIntroSec: any;
  applicationCaseSec: any;
  customersSec: any;


  /**
   * 
   * @param store 
   * @param route 
   * @param dialog 
   * @param router 
   * @param cdr 
   */
  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    public cdr: ChangeDetectorRef,
    public _sanitizer: DomSanitizer,
    public fileHelper: HelperService,
  ) { }

  ngOnInit() {
    /**
     * fetch landing by uri param
     */
    this.store.select('product').pipe(
      take(1),
      map(store => {
        return store.landing;
      })
    ).subscribe((landing: Landing) => {
      const landingParam = this.route.snapshot.params['landing'];
      if(landing.uri === landingParam) {
        this.landing = landing;

        // fill ses
        this.chemThreatSec = this.getSectionByKey("chemische-gefahrenerkennung");
        this.videoIntroSec = this.getSectionByKey("konkurrenzlos");
        this.applicationCaseSec = this.getSectionByKey("anwendungsfaelle");
        this.customersSec = this.getSectionByKey("einsatz-in-der-praxis");
      } else {
        this.landing = null;
      }
      this.cdr.detectChanges();
    });
  }

  videoUrl(url) {
    return this._sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * returns the section by 
   * the given key
   * 
   * @param key 
   * @returns 
   */
  getSectionByKey(key: string) {
    if(this.landing.section.length) {
      const section = this.landing.section.filter(s => s.key === key);
      return section.length ? section[0] : false;
    }
    return false;
  }

  /**
   * maximize the image
   * to view in full size
   */
  askForProduct(product: Product) {
    const dialogRef = this.dialog.open(DialogContactByproductContent, {
      data: {
        product: product
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}


@Component({
  selector: 'dialog-contact-byproduct-content',
  template: `
    <mat-dialog-content>
      <div>
        <mat-icon mat-dialog-close aria-hidden="false" aria-label="einklappen" [ngClass]="'clickable close-icon'">close</mat-icon>
        <div class="popup-contact">
          <app-contact-form [prefilledMessage]="defaultMessage"></app-contact-form>
        </div>
      </div>
    </mat-dialog-content>
  `,
})
export class DialogContactByproductContent {
  defaultMessage: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.defaultMessage = `Guten Tag,
ich interessiere mich für das Produkt "` + data.product.title + `".
Bitte senden Sie mir dazu folgende Informationen:
 - ...
 - ...

Vielen Dank!
    `
  }
}
