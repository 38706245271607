import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Brand } from '../brand.model';
import * as fromApp from '../../store/app.reducer';
import * as fromBrand from '../store/brand.reducer';

import * as ProductActions from '../../product/store/product.actions';

import * as fromProduct from '../../product/store/product.reducer';

@Component({
  selector: 'app-brand-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  brands: Observable<fromBrand.State>;

  products: Observable<fromProduct.State>;

  constructor(private store: Store<fromApp.AppState>) {
    // fetch data
    this.store.dispatch(new ProductActions.FetchProducts());
  }

  ngOnInit() {
    this.brands = this.store.select('brand');
    this.store.select('brand').subscribe(a => {
      console.log(a.brands);
    });

    this.store.select('product').subscribe(a => {
      console.log(a.products)
    })
  }
}
