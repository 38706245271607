import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, map, switchMap } from 'rxjs/operators';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromApp from '../store/app.reducer';
import { Productgroup } from './productgroup.model';
import * as fromProductgroup from './store/productgroup.reducer';
import * as ProductgroupActions from './store/productgroup.actions';

@Injectable({ providedIn: 'root' })
export class ProductgroupResolverService implements Resolve<Productgroup[]> {
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select('productgroup').pipe(
      take(1),
      map((productgroupState: fromProductgroup.State) => {
        return productgroupState.productgroups;
      }),
      switchMap(productgroups => {
        if (productgroups.length === 0) {
          this.store.dispatch(new ProductgroupActions.FetchProductgroups());
          return this.actions$.pipe(
            ofType(ProductgroupActions.SET_PRODUCTGROUPS),
            take(1)
          );
        } else {
          return of(productgroups);
        }
      })
    );
  }
}
