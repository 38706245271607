import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from '../layout/app-layout/app-layout.component';
import { AuthGuardService } from '../auth/auth-guard.service';
import { BlockResolverService } from '../shared/cms/block-resolver.service';
import { OrderableProductResolverService } from '../product/orderable-product-resolver.service';
import { ProductResolverService } from '../product/product-resolver.service';
import { OrderComponent } from './order.component';

const OrderRoutes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: '/order', pathMatch: 'full' },
      {
        path: 'order',
        component: OrderComponent,
        canActivate: [AuthGuardService],
        resolve: [OrderableProductResolverService, BlockResolverService],
        data: {
          pageTitle: 'wiesel DEFENCE Ersatzteile-Zusammenstellung',
          animation: ''
        }
      }
    ],
    data: {
      animation: 'App',
      pageTitle: 'wiesel DEFENCE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(OrderRoutes)],
  exports: [RouterModule]
})
export class OrderRoutingModule {}
