import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppRoutingService } from '../../core/app-routing.service';
import * as ContactFormActions from './store/contact-form.actions';
import * as fromApp from '../../store/app.reducer';
import * as fromContactForm from './store/contact-form.reducer';
import { Contact } from './contact.model';
import { Institution } from './institution.enum';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { DirectusStatus } from '../../core/directus-status.enum';
import { NotificationService } from '../../core/error-handler/notification.service';
import { File as FileModel } from '../../file/file.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  maxAllowedFileSize: number = 2; // in MB
  currentPageTitle: string;
  contactFormStore: Observable<fromContactForm.State>;
  contactSubscription: Subscription;
  filetype: string;
  pgpPublicKey: string = environment.linkToPGPPublicKey;
  @ViewChild('inputFile', {static: false}) nativeInputFile: ElementRef;
  @Input('prefilledMessage') prefilledMessage = '';
  currentFileName: string;
  //@ViewChild('contactForm',{ static:false }) public contactForm: NgForm;

  contactForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    name: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', [Validators.required]),
    institution: new UntypedFormControl('', [Validators.required]),
    file: new UntypedFormControl('')
  });

  fileProcessing = false;
  contactSending = false;
  contactSentSuccessful = false;
  contactErrors = [];
  
  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private appRoutingService: AppRoutingService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.currentPageTitle = this.appRoutingService.getRouteTitle();
    
    // check state
    this.contactSubscription = this.store.select('contact').subscribe(contactState => {
      this.contactSending = contactState.contactInSending;
      this.contactSentSuccessful = contactState.contactSent && !contactState.contactFailMessages.length

      // check error state
      if(contactState.contactFailMessages.length) {
        this.contactErrors = contactState.contactFailMessages;
      }
    });

    // set prefilled message if set
    if(this.prefilledMessage && !this.contactForm.controls.message.value) {
      this.contactForm.controls.message.setValue(this.prefilledMessage);
    }
  }

  ngOnDestroy() {
    if(this.contactSubscription) {
      this.contactSubscription.unsubscribe();
    }
  }

  /**
   * read the file input as
   * base 64
   */
  onNativeInputFileSelect($event) {
    this.fileProcessing = true;
    const inputValue = $event.target;
    const file: File = inputValue.files[0];
    const fileReader: FileReader = new FileReader();

    // skip if file is empty
    if(!file) {
      return;
    }

    this.currentFileName = file.name;

    // check size in mb
    if(file.size / 1024 / 1024 > this.maxAllowedFileSize) {
      this.notificationService.showError('Upload nicht zulässig', 'Bitte laden Sie eine Datei mit maximal 2MB hoch.');
      return;
    }

    this.filetype = file.type;
    if(!file.type
      .toLowerCase()
      .match(/(.+)\/(jpg|png|jpeg|zip|docx|xlsx)$/)) {
      this.notificationService.showError('Upload nicht zulässig', 'Bitte laden nur Dateien mit folgendem Format hoch: .jpg, .png, .jpeg, .zip, .docx, .xlsx.');
      return;
    }

    // wait for the file to be processed
    fileReader.onloadend = (e) => {
      this.fileProcessing = false;
      this.contactForm.controls.file.setValue(
        new FileModel(file.name, fileReader.result + '')
      );
    }
    fileReader.readAsDataURL(file);
  }

  /**
   * faked click event through
   * a nice looking button
   */
  selectFile() {
    this.nativeInputFile.nativeElement.click();
  }

  /**
   * submit contacts form
   */
  submitContact(e) {
    const createdOn = new Date();
    
    if(this.contactForm.valid) {
      // setup the contact model
      const contact: Contact = new Contact(
        null,
        DirectusStatus.published,
        this.contactForm.controls.name.value,
        this.contactForm.controls.email.value,
        'de-DE',
        this.contactForm.controls.institution.value,
        this.router.url,
        /**
         * @todo get current product from store if setup
         */
        null,
        this.currentPageTitle,
        this.contactForm.controls.message.value,
        null,
        createdOn.toISOString().slice(0, 19).replace('T', ' '),
        1
      );

      if(this.contactForm.controls.file) {
        this.store.dispatch(new ContactFormActions.SendContactWithFile({
          contact: contact,
          file: this.contactForm.controls.file.value,
          filetype: this.filetype
        }))
      } else {
        this.store.dispatch(new ContactFormActions.SendContact(contact));
      }
    } else {
      // show an error
      console.log(this.contactForm.valid);
      console.log(this.contactForm.errors)
    }
  }
}
