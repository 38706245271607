import { Action } from '@ngrx/store';
import { Brand } from '../brand.model';

export const FETCH_BRANDS = '[Brand] FETCH FETCH_BRANDS';
export const SET_BRANDS = '[Brand] SET_BRANDS';
export const ADD_BRAND = '[Brand] ADD_BRAND';
export const UPDATE_BRAND = '[Brand] UPDATE_BRAND';
export const STORE_BRAND = '[Brand] STORE_BRAND';
export const DELETE_BRAND = '[Brand] DELETE_BRAND';
export const START_EDIT = '[Brand] START_EDIT';
export const STOP_EDIT = '[Brand] STOP_EDIT';

export class FetchBrands implements Action {
  readonly type = FETCH_BRANDS;
}

export class SetBrands implements Action {
  readonly type = SET_BRANDS;

  constructor(public payload: { brands: Brand[] }) {}
}

export class AddBrand implements Action {
  readonly type = ADD_BRAND;
  constructor(public payload: Brand) {}
}

export class UpdateBrand implements Action {
  readonly type = UPDATE_BRAND;
  constructor(public payload: { brand: Brand; index: number }) {}
}

export class StoreBrand implements Action {
  readonly type = STORE_BRAND;
  constructor(public payload: number) {}
}

export class DeleteBrand implements Action {
  readonly type = DELETE_BRAND;
}

/**
 * to dispatch, when the page for
 * editing is opened to keep track
 * of the current editing id
 */
export class StartEdit implements Action {
  readonly type = START_EDIT;
  constructor(public payload: number) {}
}

export class StopEdit implements Action {
  readonly type = STOP_EDIT;
}

export type BrandActions =
  | AddBrand
  | UpdateBrand
  | StartEdit
  | StopEdit
  | FetchBrands
  | SetBrands
  | DeleteBrand
  | StoreBrand;
