import { Action } from '@ngrx/store';
import { Product } from '../product.model';
import { Landing } from '../landing.model';

export const FETCH_PRODUCTS = '[products] FETCH_PRODUCTS';
export const SET_PRODUCTS = '[products] SET_PRODUCTS';
export const FETCH_ORDERABLE_PRODUCTS = '[products] FETCH_ORDERABLE_PRODUCTS';
export const SET_ORDERABLE_PRODUCTS = '[products] SET_ORDERABLE_PRODUCTS';
export const SET_LANDING = '[products] SET_LANDING';
export const FETCH_LANDING = '[products] FETCH_LANDING';

export class FetchProducts implements Action {
  readonly type = FETCH_PRODUCTS;
}

export class SetProducts implements Action {
  readonly type = SET_PRODUCTS;

  constructor(public payload: {products: Product[]}) {}
}

export class FetchOrderableProducts implements Action {
  readonly type = FETCH_ORDERABLE_PRODUCTS;
}

export class SetOrderableProducts implements Action {
  readonly type = SET_ORDERABLE_PRODUCTS;

  constructor(public payload: {products: Product[]}) {}
}

export class FetchLanding implements Action {
  readonly type = FETCH_LANDING;

  constructor(public payload: {landingSlug: string}) {}
}

export class SetLanding implements Action {
  readonly type = SET_LANDING;

  constructor(public payload: { landing: Landing }) {}
}

export type ProductActions = FetchProducts | SetProducts | SetOrderableProducts | FetchOrderableProducts | SetLanding | FetchLanding;