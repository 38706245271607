import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { TweenMax, Power2, TimelineLite, Power3, Power1 } from 'gsap';
import { Subscription, interval, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as $ from 'jquery';
import * as LayoutActions from '../../layout/store/layout.actions';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  public loadingTimerSubscription: Subscription;
  public finisherIntervalSubscription: Subscription;
  public loadingPercentage: number = 0;
  public holdProgressBar: boolean= false;
  @ViewChild('holdProgressBarElement', {static: false}) holdProgressBarElement: ElementRef;

  constructor(
    private store: Store<fromApp.AppState>,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

  }

  ngAfterViewChecked() {

  }

  ngAfterViewInit() {
    if ($('#page-content').hasClass("light-content")) {
      $('.preloader-wrap').addClass('light-content');
    }
    
    
    let width = 100;
    let perfData = window.performance.timing;
    let EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart);
    let time = ((EstimatedTime/1000)%50) * 10;
      
    // Loadbar Animation
    // $(".loadbar").animate({
    //   width: width + "%"
    // }, time / 0.8);
    
    
    // Percentage Increment Animation
    let start = 0;
    let end = 100;
    let durataion = 0;
    
    this.animateValue(start, end, durataion);
        
    // Fading Out Loadbar on Finised
    this.preparePageFinished(time);
  }

  /**
   * start displaying content
   * and animate
   */
  preparePageFinished(timeoutTime: number) {
    setTimeout(() => {
      this.holdProgressBar = true;

      TweenMax.to($('.hold-progress-bar'), 1, {force3D:true,width:'100%', delay:0.1, ease:Power2.easeOut, onComplete: () => {
        TweenMax.set($(".trackbar"), {visibility:'hidden', opacity:0});
        // $('body').waitForImages({
        //   finished: () => {
            
            TweenMax.to($(".percentage"), {force3D:true, opacity:0, y:-90, delay:0, ease:Power1.easeInOut});
            TweenMax.to($(".preloader-wrap"), {force3D:true, yPercent: -101, delay:0.6, ease:Power2.easeInOut});
            TweenMax.set($(".preloader-wrap"), {visibility:'hidden', delay:1.4, opacity:0});
            
            setTimeout(() => {
            
              // $('body').waitForImages({
              //   finished: function() {
              //     TweenMax.to($("#header-container, #footer-container"), 1, {force3D:true, opacity:1, delay:0.3, ease:Power2.easeOut});
              //     $('body').removeClass('hidden-ball');		
              //   },
              //   waitForAll: true
              // });
              
              // Fading In Showcase elements on Finised
              TweenMax.set($("#showcase-holder"), {opacity:0, scale:1.1});			
              if($(".swiper-pagination-bullet-active .subtitle"))
              TweenMax.set($(".swiper-pagination-bullet-active .subtitle"), {opacity:0, transform: 'translate3d(0,15vh,0)', delay:0});
              TweenMax.set($(".swiper-pagination-bullet-active .title"), {opacity:0, transform: 'translate3d(0,15vh,0)', delay:0});
              TweenMax.set($(".footer-button-wrap"), {opacity:0, transform: 'translate3d(0,15vh,0)', delay:0, ease:Power2.easeOut});
              TweenMax.set($(".showcase-counter, .swiper-pagination-bullet-active .counter, .arrows-wrap"), {opacity:0, delay:0,});
              
              TweenMax.to($("#showcase-holder"), 0.8, {force3D:true, opacity:1, scale:1, delay:0.8, ease:Power2.easeOut});
              TweenMax.to($(".swiper-pagination-bullet-active .subtitle"), 0.4, {force3D:true, opacity:1, y:0, delay:0.9, ease:Power2.easeOut});
              TweenMax.to($(".swiper-pagination-bullet-active .title"), 0.4, {force3D:true, opacity:1, y:0, delay:0.95, ease:Power2.easeOut});
              TweenMax.to($(".footer-button-wrap"), 0.4, {force3D:true, opacity:1, y:0, delay:1, ease:Power2.easeOut});
              TweenMax.to($(".showcase-counter, .swiper-pagination-bullet-active .counter, .arrows-wrap"), 0.3, {force3D:true, opacity:1, delay:0.95, ease:Power2.easeOut});
              
              // Fading In Small Carousel elements on Finised
              var tlCarousel = new TimelineLite();
              tlCarousel.set($("#showcase-carousel .swiper-slide"), {x: 300, opacity:0});
              $("#showcase-carousel .swiper-slide").each(function(index, element) {
                tlCarousel.to(element, 1.4, {x:0, opacity:1, delay:0.9, ease:Power3.easeOut}, index * 0.1)
              });
              // Fading In Large Carousel elements on Finised
              var tlCarousel = new TimelineLite();
              tlCarousel.set($("#large-showcase-carousel .swiper-slide"), {x: 300, opacity:0});
              $("#large-showcase-carousel .swiper-slide").each(function(index, element) {
                tlCarousel.to(element, 1.4, {x:0, opacity:1, delay:0.9, ease:Power3.easeOut}, index * 0.1)
              });
              
              TweenMax.set($(".swiper-scrollbar"), {scaleX: 0,});
              TweenMax.to($(".swiper-scrollbar"), 1.2, {force3D:true, scaleX: 1, delay:0.9, ease:Power2.easeOut});
              
              
              
              setTimeout( function(){
                $('body').removeClass("load-project-page");
              } , 100 );
              
              // setTimeout( function(){
                $('body').removeClass("load-next-project");
                $('body').addClass("header-visible");
                $('#showcase-holder').removeClass("disabled");
              // } , 100 );
              
              setTimeout( () => {
                $('body').removeClass('hidden');
                $('html').removeClass('hidden');


                $('body').removeClass("show-loader")
                this.store.dispatch(new LayoutActions.ContentPreloaded({ state: true}));
                TweenMax.to($("#main"), 0.2, {force3D:true, opacity:1, delay:0.1, ease:Power2.easeOut});
              } , 100 );
            } , 200 );
        //   },
        //   waitForAll: true
        // });
      }});
    }, timeoutTime);
  }

  /**
   * animate the percantge state
   */
  animateValue(start, end, duration) {
    const range = end - start;
    let current = start;
    let increment = end > start? 1 : -1;
    let stepTime = Math.abs(Math.floor(duration / range));

    this.loadingTimerSubscription = interval(stepTime).subscribe(() => {
      current += increment;
      this.loadingPercentage = current;
      if(current == end) {
        this.loadingTimerSubscription.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.loadingTimerSubscription.unsubscribe();
  }
}
