import { Component, OnInit } from '@angular/core';
import { Block } from '../../shared/cms/block.model';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';
import { Brand } from '../../brand/brand.model';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { FileImageModifier, HelperService } from 'src/app/file/helper.service';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  brands: Brand[];
  headerTitle: string = 'wiesel DEFENCE';
  headerSubtitle: string = 'offizieller Vertriebspartner für Deutschland';
  brandsContentBlock: Block;
  brandsContentBlockIdentifier: string = 'brands_content';

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private fileHelper: HelperService
    ) { }

  ngOnInit() {
    this.store.select('block').pipe(
      take(1),
      map(store => {
        const filtered = store.blocks.filter(block => block.identifier === this.brandsContentBlockIdentifier);
        if(filtered) {
          return filtered[0];
        }
        return null;
      })
    ).subscribe(block => {
      this.brandsContentBlock = block;
    });

    /**
     * fetch brands for the home page
     */
    this.store.select('brand').pipe(
      take(1),
      map(store => {
        return store.brands;
      })
    ).subscribe(brands => {
      this.brands = brands
    });
  }

}
