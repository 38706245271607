import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProductActions from './product.actions';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { DirectusService } from '../../core/directus.service';
import * as fromApp from '../../store/app.reducer';
import { Product } from '../product.model';
import { Brand } from '../../brand/brand.model';
import { Category } from '../../category/category.model';
import { FileImageModifier, HelperService } from 'src/app/file/helper.service';
import { Landing } from '../landing.model';

const applyLanding = (landingData: any, scope: any): Landing => {
  let altImage = landingData.altImage ? scope.fileHelper.getImage(landingData.altImage.filename_disk, {
    fit: "cover",
    width: 850,
    height: 650,
    quality: 90
  } as FileImageModifier)  : null;

  let image = landingData.image ? scope.fileHelper.getImage(landingData.image.filename_disk, {
    fit: "contain",
    width: 850,
    height: 650,
    quality: 80
  } as FileImageModifier)  : null;

  const landing = new Landing(
    landingData.id,
    landingData.status,
    landingData.uri,
    landingData.title,
    landingData.subtitle,
    landingData.description,
    landingData.section,
    altImage,
    image,
    landingData.brand,
    landingData.product,
    landingData.menuLabel
  );
  return landing;
};

const applyProducts = (productsData: any, scope: any): Product[] => {
  return productsData.data.map(product => {
    // load product brand object
    let brand = null;
    if(product.brand) {
      brand = new Brand(
        product.brand.id,
        product.brand.title,
        product.brand.description,
        product.brand.content,
        product.brand.short_description,
        product.brand.claim,
        product.brand.language,
        product.brand.status,
        product.brand.logo,
        product.brand.logoThumbnail,
        product.brand.header_image,
        product.brand.alt_image,
        product.brand.uri_identifier
      )
    }

    // product images
    let mainImage = product.main_image ? scope.fileHelper.getImage(product.main_image.filename_disk, {
      fit: "contain",
      width: 450,
      height: 450
    } as FileImageModifier) : null;
    let headerTeaser = product.header_teaser ? scope.fileHelper.getImage(product.header_teaser.filename_disk, {
      fit: "contain",
      width: 850,
      height: 650,
      quality: 90
    } as FileImageModifier)  : null;
    let videoUrl = product.video ? scope.fileHelper.getFile(product.video) : null;

    // product gallery images
    let galleryImages = [];
    if(product.gallery_images) {
      product.gallery_images.forEach((item) => {
        if(item.directus_files_id) {
          galleryImages.push(scope.fileHelper.getImage(item.directus_files_id));
        }
      });
    }
    
    // add category
    let category: Category = null;
    if(product.category) {
      category = new Category(
        product.category.id,
        product.category.title
      );
    }

    //product.brand
    return new Product(
      product.id,
      product.sku ? product.sku : product.uri_identifier,
      product.status,
      product.product_details_active,
      product.sort,
      product.created_by,
      product.created_on,
      product.title,
      product.claim,
      product.short_description,
      product.description,
      product.language,
      product.can_be_ordered,
      brand,
      mainImage,
      headerTeaser,
      galleryImages,
      product.uri_identifier,
      product.facts,
      category,
      videoUrl,
      product.product_list_mode,
      product.landing ? product.landing : null
    );
  });
};

@Injectable()
export class ProductEffects {
  fetchProducts = createEffect(() => this.actions$.pipe(
    ofType(ProductActions.FETCH_PRODUCTS),
    switchMap(() => {
      const client = this.directus.getClient();
      return from(client.items('products').readByQuery({
                              fields: '*,brand.*, header_teaser.*, main_image.*,gallery_images.*,category.*,videos.*,landing.uri, landing.id',
                              filter: {
                                "can_be_ordered": {
                                  '_eq': 0
                                }
                              }
                            }));
    }),
    map((data): Product[] => {
      return applyProducts(data, this);
    }),
    map((products: Product[]) => {
      return new ProductActions.SetProducts({products});
    })
  ));

  
  fetchOrderableProducts = createEffect(() => this.actions$.pipe(
    ofType(ProductActions.FETCH_ORDERABLE_PRODUCTS),
    switchMap(() => {
      const client = this.directus.getClient();
      return from(client.items('products').readByQuery({
                              fields: '*,brand.*, header_teaser.*, main_image.*,gallery_images.*,category.*,videos.*,landing.uri, landing.id',
                              filter: {
                                "can_be_ordered": {
                                  '_eq': 1
                                }
                              }
                            }));
    }),
    map((data): Product[] => {
      return applyProducts(data, this);
    }),
    map((products: Product[]) => {
      return new ProductActions.SetOrderableProducts({products});
    })
  ));

  fetchLanding = createEffect(() => this.actions$.pipe(
    ofType(ProductActions.FETCH_LANDING),
    switchMap((fetchLandingAction: ProductActions.FetchLanding) => {
      const client = this.directus.getClient();
      return from(client.items('landing').readByQuery({ fields: '*,brand.*, section.*, product.*, image.*, altImage.*', filter: { "uri": { '_eq': fetchLandingAction.payload.landingSlug } }}));
    }),
    map((data: any): Landing => {
      return applyLanding(data.data[0], this);
    }),
    map((landing: Landing) => {
      return new ProductActions.SetLanding({ landing });
    })
  ));
  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>, private fileHelper: HelperService) {}
}