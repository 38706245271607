import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, map, switchMap } from 'rxjs/operators';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromApp from '../../store/app.reducer';
import { Newsticker } from './newsticker.model';
import * as fromNewsticker from './store/newsticker.reducer';
import * as NewstickerActions from './store/newsticker.actions';

@Injectable({ providedIn: 'root' })
export class NewstickerResolverService implements Resolve<Newsticker[]> {
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select('newsticker').pipe(
      take(1),
      map((newstickerState: fromNewsticker.State) => {
        return newstickerState.newstickers;
      }),
      switchMap(newstickers => {
        if (newstickers.length === 0) {
          this.store.dispatch(new NewstickerActions.FetchNewstickers());
          return this.actions$.pipe(
            ofType(NewstickerActions.SET_NEWSTICKERS),
            take(1)
          );
        } else {
          return of(newstickers);
        }
      })
    );
  }
}
