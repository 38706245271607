import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { Product } from '../../product/product.model';
import { User } from 'parse';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  public currentProduct: Product;
  public defaultContactMessage: string;
  public productFacts: (string|{label: string, value: string})[] = [];
  public authSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    const productParam = this.route.snapshot.params['product'];

    /**
     * fetch brand by uri param
     */
    this.store.select('product').pipe(
      take(1),
      map(store => {
        let filtered = store.products.filter(product => product.uriIdentifier == productParam);
        if(filtered.length) {
          return filtered[0];
        }
        return null;
      })
    ).subscribe(product => {
      this.currentProduct = product;
      // prepare fact data
      this.getFacts();
    });


    /**
     * fetch brand by uri param
     */
    this.authSubscription = this.store.select('auth').pipe(
      map(store => {
        return store.user
      }
      ))
      .subscribe((user: any) => {
        if(!user) {
          user = JSON.parse(localStorage.getItem("userData"));
        }
        if(this.currentProduct?.brand?.uriIdentifier === "steiner") {
          if(user === null || (user.tags && user.tags?.indexOf("steiner-access") === -1)) {
            this.router.navigate(["/brand-login"]);
          }
        }
      });

    /**
     * preset message for contact form
     */
    this.defaultContactMessage = `Guten Tag,
ich interessiere mich für das Produkt "` + this.currentProduct.title + `".
Bitte senden Sie mir dazu folgende Informationen:
 - ...
 - ...

Vielen Dank!
    `
  }

  /**
   * returns pre rendered
   * facts from the databse
   */
  getFacts() {
    if(this.currentProduct && this.currentProduct.facts?.length) {
      this.productFacts = this.currentProduct.facts
        .filter(item => {
          if(item != '') {
            return true
          }
          return false;
        })
        .map(item => {
          const itemParts: string[] = item.split(': ');
          if(itemParts.length == 2) {
            return {
              label: itemParts[0],
              value: itemParts[1]
            }
          }
          return item;
        });
    }
  }
  
  ngOnDestroy() {
    if(this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
