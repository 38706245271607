import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HomepageComponent } from '../pages/homepage/homepage.component';
import { ProductgroupModule } from '../productgroup/productgroup.module';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { BrandsComponent } from './brands/brands.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { BrandComponent, DialogContactByproductContent } from './brand/brand.component';
import { LandingComponent } from '../product/landing/landing.component';
import { SerstechArxComponent } from '../product/landing/components/serstech-arx/serstech-arx.component';
import { ComplianceComponent } from './compliance/compliance.component';

@NgModule({
  declarations: [DialogContactByproductContent, HomepageComponent, ImprintComponent, PrivacyComponent, BrandsComponent, NewsComponent, ContactComponent, AboutComponent, BrandComponent, LandingComponent, SerstechArxComponent, ComplianceComponent],
  // entryComponents: [DialogContactByproductContent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    ProductgroupModule
  ]
})
export class PagesModule { }
