import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../../auth/store/auth.actions';
import { TimelineLite, Power2 } from 'gsap';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalStorage } from '@directus/sdk';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {
  mobileMenuOpened = false;
  title: string;
  @ViewChild('headerContainer', {static: false}) headerContainer;
  headerContainerOpacity = 0;
  isLoggedIn = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
  ) {}

  ngOnInit() {
    this.headerContainerOpacity = 1;
    const auth = JSON.parse(localStorage.getItem("authData"));
    this.isLoggedIn = auth && auth._token
  }

  ngDoCheck() {
    // subscribing to data not working with childs
    if (
      !this.route.firstChild &&
      this.route.firstChild.snapshot !== undefined
    ) {
      this.title = this.route.firstChild.snapshot.data.pageTitle;
    }
  }

  logoutAction() {
    this.store.dispatch(new AuthActions.Logout());
  }

  clickMenuItem() {
    if(this.mobileMenuOpened) {
      this.openMobileMenu();
    }
  }

  openMobileMenu() {
    if(!this.mobileMenuOpened) {
      this.mobileMenuOpened = true;
    } else {
      this.mobileMenuOpened = false;
      //Fade Out Navigation Lists          
    }
  }
}
