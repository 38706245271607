import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import * as fromAuth from '../store/auth.reducer';
import * as AuthActions from '../store/auth.actions';
import { UntypedFormControl, Validators, UntypedFormGroup, ValidationErrors, AbstractControl } from '@angular/forms';


const matchValues = function(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
}

@Component({
  selector: 'app-brand-protected-register',
  templateUrl: './brand-protected-register.component.html',
  styleUrls: ['./brand-protected-register.component.scss']
})
export class BrandProtectedRegisterComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  @ViewChild('registerForm', { static: false }) registerForm;
  successfullSent = false;
  loading = false;
  registerFormR = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    firstname: new UntypedFormControl('', [Validators.required]),
    lastname: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+/)]),
    passwordConfirm: new UntypedFormControl('', [Validators.required, matchValues('password')]),
  });

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.authSubscription = this.store.select('auth').subscribe((state) => {
      if(this.loading === true
        && (state.authReqSent || state.authError)) {
        this.loading = false;
        this.successfullSent = true;
      }
    });
  }

  ngOnDestroy() {
    if(this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  register() {
    const registerForm = this.registerFormR;
    this.loading = true;

    this.store.dispatch(
      new AuthActions.SignupStart({
        email: registerForm.controls.email.value,
        firstname: registerForm.controls.firstname.value,
        lastname: registerForm.controls.lastname.value,
        password: registerForm.controls.password.value,
        tags: ["steiner-access"]
      })
    );
  }
}
