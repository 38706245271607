import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';

@Component({
  selector: 'app-special-item',
  templateUrl: './special-item.component.html',
  styleUrls: ['./special-item.component.scss']
})
export class SpecialItemComponent implements OnInit {
  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  getDetails() {
    this.router.navigate(["/special/product/", "serstech-arx"])
  }
}
