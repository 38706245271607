import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ImageTiltDirective } from './directives/image-tilt.directive';
import { SmoothScrollDirective } from './directives/smooth-scroll.directive';
import { AnimationDirective } from './directives/animation.directive';
import { HeroDirective } from './directives/hero.directive';
import { ShowcaseSliderDirective } from './directives/showcase-slider.directive';
import { ParallaxDirective } from './directives/parallax.directive';
import { PreloaderComponent } from './preloader/preloader.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { NewsletterSubscribeComponent } from './newsletter-subscribe/newsletter-subscribe.component';
import { NewstickerComponent } from './newsticker/newsticker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxCarouselModule } from 'ngx-light-carousel';
import { UnobstrusiveContentComponent } from './unobstrusive-content/unobstrusive-content.component';
import { SpecialItemComponent } from './special-item/special-item.component';

import {
MatFormFieldModule
} from '@angular/material/form-field';
import {
  MatInputModule
} from '@angular/material/input';
import {
  MatSelectModule
} from '@angular/material/select';
import {
  MatRadioModule
 } from '@angular/material/radio';
import {
  MatButtonModule
} from '@angular/material/button';
import {
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import {
  MatStepperModule
} from '@angular/material/stepper';
import {
  MatIconModule
} from '@angular/material/icon';
import {
  MatDialogModule
} from '@angular/material/dialog';
import {
  MatProgressSpinnerModule
} from '@angular/material/progress-spinner';
import {
  MatTableModule,
} from '@angular/material/table';
import {
  MatExpansionModule
} from '@angular/material/expansion';
import {
  MatGridListModule
} from '@angular/material/grid-list';
import { FilterPipe } from './pipes/filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
 
@NgModule({
  declarations: [
    ImageTiltDirective,
    ShowcaseSliderDirective,
    HeroDirective,
    SmoothScrollDirective,
    AnimationDirective,
    ParallaxDirective,
    PreloaderComponent,
    ContactFormComponent,
    NewsletterSubscribeComponent,
    NewstickerComponent,
    FilterPipe,
    SafePipe,
    UnobstrusiveContentComponent,
    SpecialItemComponent
  ],
  entryComponents: [ContactFormComponent],
  imports: [
    BrowserModule,
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatSnackBarModule,
    NgxCarouselModule,
    MatStepperModule,
    MatIconModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatGridListModule
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    PreloaderComponent,
    ImageTiltDirective,
    MatDialogModule,
    ParallaxDirective,
    HeroDirective,
    AnimationDirective,
    ShowcaseSliderDirective,
    SmoothScrollDirective,
    ContactFormComponent,
    NewsletterSubscribeComponent,
    NewstickerComponent,
    FilterPipe,
    SafePipe,
    UnobstrusiveContentComponent,
    MatExpansionModule,
    MatGridListModule,
    MatTableModule,
    SpecialItemComponent
  ]
})
export class SharedModule {}
