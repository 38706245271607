import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NewstickerActions from './newsticker.actions';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { DirectusService } from '../../../core/directus.service';
import * as fromApp from '../../../store/app.reducer';
import { Newsticker } from '../newsticker.model';
import { HelperService } from 'src/app/file/helper.service';

@Injectable()
export class NewstickerEffects {
  
  fetchNewstickers = createEffect(() => this.actions$.pipe(
    ofType(NewstickerActions.FETCH_NEWSTICKERS),
    switchMap(() => {
      const client = this.directus.getClient();
      return from(client.items('news').readByQuery({fields: 'state,*,gallery_images.*,main_image.*'}));
    }),
    map((newstickersData: any) => {
      return newstickersData.data.filter(n => n.status === "published").map(newsticker => {

        // product gallery images
        let galleryImages = [];
        if(newsticker.gallery_images) {
          newsticker.gallery_images.forEach((item) => {
            if(item.directus_files_id) {
              galleryImages.push(this.fileHelper.getImage(item.directus_files_id));
            }
          });
        }

        let image = null;
        if(newsticker.main_image) {
          image = this.fileHelper.getImage(newsticker.main_image)
        }

        return new Newsticker(
          newsticker.id,
          newsticker.event_date,
          newsticker.status,
          newsticker.language,
          newsticker.title,
          newsticker.short_description,
          newsticker.content,
          galleryImages,
          image
        );
      });
    }),
    map((newstickers: Newsticker[]) => {
      return new NewstickerActions.SetNewstickers({newstickers});
    })
  ));

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>, private fileHelper: HelperService) {}
}
