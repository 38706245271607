export class User {
  constructor(
    public id: number,
    public username: string,
    public email: string,
    public firstname: string,
    public lastname: string,
    public birthdate: Date,
    public locale: string,
    public tags: string[]
  ) {}
}
