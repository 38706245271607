import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, map, switchMap } from 'rxjs/operators';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromApp from '../store/app.reducer';
import * as fromProduct from './store/product.reducer';
import * as ProductActions from './store/product.actions';
import { Landing } from './landing.model';

@Injectable({ providedIn: 'root' })
export class LandingResolverService implements Resolve<Landing> {
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select('product').pipe(
      take(1),
      switchMap((productState: fromProduct.State) => {
        if (!productState.landing || productState.currentLoadLanding === null || productState.currentLoadLanding !== route.params.landing) {
          this.store.dispatch(new ProductActions.FetchLanding({ landingSlug: route.params.landing }));

          return this.actions$.pipe(
            ofType(ProductActions.SET_LANDING),
            take(1)
          );
        } else {
          return of(productState.landing);
        }
      })
    );
  }
}
