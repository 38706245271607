import { User } from '../user/user.model';

export class Brand {
  constructor(
    public id: number,
    public title: string,
    public description: string,
    public content: string,
    public shortDescription: string,
    public claim: string,
    public language: string,
    public status: string,
    public logo: string,
    public logoThumbnail: string,
    public header: string,
    public altImage: string,
    public uriIdentifier: string
  ) {}
}
