import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProductgroupActions from './productgroup.actions';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { DirectusService } from '../../core/directus.service';
import * as fromApp from '../../store/app.reducer';
import { Productgroup } from '../productgroup.model';

@Injectable()
export class ProductgroupEffects {
  
  fetchProductgroups = createEffect(() => this.actions$.pipe(
    ofType(ProductgroupActions.FETCH_PRODUCTGROUPS),
    switchMap(() => {
      const client = this.directus.getClient();
      return from(client.items('productgroups').readByQuery());
    }),
    map((productgroupsData: any) => {
      return productgroupsData.data.map(productgroup => {
        return new Productgroup(
          productgroup.id,
          productgroup.status,
          productgroup.sort,
          productgroup.created_by,
          productgroup.created_on,
          productgroup.name,
          productgroup.short_description,
          productgroup.description,
          productgroup.language,
          productgroup.image
        );
      });
    }),
    map((productgroups: Productgroup[]) => {
      return new ProductgroupActions.SetProductgroups({productgroups});
    })
  ));

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>) {}
}