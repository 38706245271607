import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, map, switchMap } from 'rxjs/operators';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromApp from '../store/app.reducer';
import { Product } from './product.model';
import * as fromProduct from './store/product.reducer';
import * as ProductActions from './store/product.actions';

@Injectable({ providedIn: 'root' })
export class OrderableProductResolverService implements Resolve<Product[]> {
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select('product').pipe(
      take(1),
      map((productState: fromProduct.State) => {
        return productState.products;
      }),
      switchMap(products => {
        if (products.length === 0) {
          this.store.dispatch(new ProductActions.FetchOrderableProducts());
          return this.actions$.pipe(
            ofType(ProductActions.SET_ORDERABLE_PRODUCTS),
            take(1)
          );
        } else {
          return of(products);
        }
      })
    );
  }
}
