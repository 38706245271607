import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, map, switchMap } from 'rxjs/operators';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromApp from '../../store/app.reducer';
import { Block } from './block.model';
import * as fromBlock from './store/block.reducer';
import * as BlockActions from './store/block.actions';

@Injectable({ providedIn: 'root' })
export class BlockResolverService implements Resolve<Block[]> {
  constructor(
    private store: Store<fromApp.AppState>,
    private actions$: Actions
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select('block').pipe(
      take(1),
      map((blockState: fromBlock.State) => {
        return blockState.blocks;
      }),
      switchMap(blocks => {
        if (blocks.length === 0) {
          this.store.dispatch(new BlockActions.FetchBlocks());
          return this.actions$.pipe(
            ofType(BlockActions.SET_BLOCKS),
            take(1)
          );
        } else {
          return of(blocks);
        }
      })
    );
  }
}
