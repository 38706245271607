import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { ErrorHandlerComponent } from '../core/error-handler/error-handler.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { CookieconsentModule } from '../cookieconsent/cookieconsent.module';

@NgModule({
  declarations: [
    AppLayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    FooterComponent,
    ErrorHandlerComponent,
    HomeLayoutComponent
  ],
  imports: [
    CommonModule,
    CookieconsentModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatGridListModule,
    FlexLayoutModule,
    SharedModule
  ]
})
export class LayoutModule {}
