import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ContactFormActions from './contact-form.actions';
import * as FileActions from '../../../file/store/file.actions';
import { switchMap, map, catchError, tap, mergeMap, filter, combineAll, takeLast, skipUntil } from 'rxjs/operators';
import { from, of, combineLatest } from 'rxjs';
import { DirectusService } from '../../../core/directus.service';
import * as fromApp from '../../../store/app.reducer';
import { Contact } from '../contact.model';

@Injectable()
export class ContactFormEffects {
  
  sendContact = createEffect(() => this.actions$.pipe(
    ofType(ContactFormActions.SEND_CONTACT),
    switchMap((sendContactAction: ContactFormActions.SendContact) => {
      const client = this.directus.getClient();
      let payload = sendContactAction.payload;
      // delete payload.id;
      return from(client.transport.post('/contact', payload)).pipe(
        map(result => {
          // dispatch action to set contact as received
          return new ContactFormActions.ContactSent();
        }),
        catchError(error => {
          console.log(error)
          return of(new ContactFormActions.ContactFailed([error.message]));
        })
      );
    })
  ));

  
  sendContactWithFile = createEffect(() => this.actions$.pipe(
    ofType(ContactFormActions.SEND_CONTACT_WITH_FILE),
    switchMap((sendContactActionWithFile: ContactFormActions.SendContactWithFile) => {
      let fileUpload = new FileActions.UploadFile(sendContactActionWithFile.payload.file);
      this.store.dispatch(fileUpload);
      let contact: Contact = sendContactActionWithFile.payload.contact;

      const waitUntilFileWasUploadedPromise = new Promise((resolve, reject) => {
        this.store.select('file').subscribe(fileState => {
          if(fileState.savedFileData) {
            resolve(fileState.savedFileData);
          }
        });
      });

      return from(waitUntilFileWasUploadedPromise)
        .pipe(
          map((savedData: any) => {
            if(savedData !== undefined) {
              contact = { ...contact,additionalInfo: savedData.id };
            }
            return new ContactFormActions.SendContact(contact);
          }),
          catchError(error => {
            console.log(error)
            return of(new ContactFormActions.ContactFailed([error.message]));
          })
        );
    })
  ));

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>) {}
}