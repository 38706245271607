export class Auth {
  constructor(private _token: string, private _tokenExpireDate: Date, private _refresh_token: string) {}

  get token() {
    if (!this._tokenExpireDate || new Date() > this._tokenExpireDate) {
      return null;
    }
    return this._token;
  }

  set refresh_token(t) {
    this._refresh_token = t;
  }

  get refresh_token() {
    return this._refresh_token
  }
}
