import { Component, OnInit } from '@angular/core';
import { Block } from '../../shared/cms/block.model';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  imprintContentBlockIdentifier: string = 'imprint_content';
  pgpPublicKey: string = environment.linkToPGPPublicKey;
  content: Block;

  constructor(
    private store: Store<fromApp.AppState>
  ) { }

  ngOnInit() {
    this.store.select('block').pipe(
      take(1),
      map(store => {
        const filtered = store.blocks.filter(block => block.identifier === this.imprintContentBlockIdentifier);
        if(filtered) {
          return filtered[0];
        }
        return null;
      })
    ).subscribe(block => {
      this.content = block;
    });
  }

}
