import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';

@Injectable({ providedIn: 'root' })
export class CookieService implements OnDestroy {
  public loginAuthTokenObservable;
  private tokenExpirationTimer: any;
  public gaProperty = '';
  public state = {
    cookiesDenied: false,
    dialogInteraction: false
  }

  constructor() {
    this.loadState();
  }

  denyCookies() {
    // deny google analytics 
    window['ga-disable-' + this.gaProperty] = true;

    this.state.cookiesDenied = true;
    this.state.dialogInteraction = true;
    this.storeState();
  }

  acceptCookies() {
    // allow google analytics 
    window['ga-disable-' + this.gaProperty] = false;

    this.state.cookiesDenied = false;
    this.state.dialogInteraction = true;
    this.storeState();
  }

  ngOnDestroy() {

  }

  storeState() {
    localStorage.setItem('cookieconsentState', JSON.stringify(this.state));
  }

  loadState() {
    let raw = localStorage.getItem('cookieconsentState');
    if(raw) {
      this.state = JSON.parse(raw);
    }
  }
}