import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Directus} from '@directus/sdk';

export interface FileImageModifier {
  fit?: string,
  width?: number,
  height?: number,
  quality?: number,
  transforms?: any,
  format?: string,
  background?: any,
  key: string,
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  client: any;

  constructor() {}

  getFile(fileId: string) {
    return `${environment.api.assets}${fileId}`;
  }

  getImage(fileId: string, modifier?: FileImageModifier) {
    let modifiers: string = "";
    if(!modifier || modifier.fit === "contain") {
      /**
       * remove custom settings if fit is contain,
       * because this does not work with te preset modifier
       */
      modifier = {} as FileImageModifier;
      modifier.key = "keep-transparency";
    }
    // modifier.format = "png";
    modifiers = "?"
    Object.entries(modifier).forEach((entry) => {
      const [key, value] = entry;
      modifiers = modifiers + "&" + key + "=" + value;
    });
    return `${environment.api.assets}${fileId}${modifiers}`;
  }

  getVideo(fileId: string) {
    return `${environment.api.assets}${fileId}`;
  }

  getThumbnail(fileId: string) {
    return this.getImage(fileId, {
      quality: 80,
      width: 140,
      height: 140
    } as FileImageModifier)
  }
}
