import * as OrderActions from './order.actions';
import { Order } from '../order.model';
import { OrderItem } from '../order-item.model';
import { Product } from '../../product/product.model'

export interface State {
  orders: Order[];
  orderableOrders: Order[];
  selectedProducts: Product[];
  preparedOrder: Order;
  preparedOrderItems: OrderItem[];
  appliedOrder: Order;
  orderSent: boolean;
  orderFailMessages: string[];
}

export const initialState: State = {
  orders: [],
  orderableOrders: [],
  selectedProducts: [],
  preparedOrder: null,
  preparedOrderItems: [],
  appliedOrder: null,
  orderSent: false,
  orderFailMessages: []
}

export function orderReducer(
  state: State = initialState,
  action: OrderActions.OrderActions
) {
  switch(action.type) {
    case OrderActions.SET_ORDERS:
      return {
        ...state,
        orders: action.payload.orders
      };
    case OrderActions.ADD_PRODUCT:
      if(!state.selectedProducts.find(item => item.id === action.payload.product.id)) {
        let item = new OrderItem(
          null,
          null,
          new Date,
          null,
          new Date,
          action.payload.qty,
          action.payload.product,
          null
        );
        return {
          ...state,
          selectedProducts: [...state.selectedProducts, action.payload.product],
          preparedOrderItems: [...state.preparedOrderItems, item]
        };
      }
      return {
        ...state
      };
    case OrderActions.REMOVE_PRODUCT:
      return {
        ...state,
        selectedProducts: state.selectedProducts.filter((product, index) => {
          return product.id !== action.payload.product.id;
        }),
        preparedOrderItems: state.preparedOrderItems.filter((item, index) => {
          return item.article.id !== action.payload.product.id;
        })
      };
    case OrderActions.CHANGE_QTY_FOR_PRODUCT:
      const updatedOrderItemIndex = state.preparedOrderItems.map(item =>  {
        return item.article.id
      }).indexOf(action.payload.product.id)
      if(updatedOrderItemIndex !== -1) {
        const updatedOrderItems = [...state.preparedOrderItems];
        const updatedOrderItem = {
          ...state.preparedOrderItems[updatedOrderItemIndex],
         };
        updatedOrderItem.quantity = action.payload.qty;
        // change updated item
        updatedOrderItems[updatedOrderItemIndex] = updatedOrderItem;
        return {
          ...state,
          preparedOrderItems: updatedOrderItems
        };
      }
      return {
        ...state
      };
    case OrderActions.APPLY_ORDER:
      return {
        ...state,
        appliedOrder: {...action.payload}
      };
    case OrderActions.ORDER_SENT:
      return {
        ...state,
        orderSent: true
      };
    case OrderActions.ORDER_FAILED:
      return {
        ...state,
        orderSent: false,
        orderFailMessages: [...action.payload]
      }
    default:
      return state;
  }
}