import { Directive, ElementRef, Input, OnChanges} from '@angular/core';
import Scrollbar from 'smooth-scrollbar';
import { Store } from '@ngrx/store';
import * as fromAppReducer from '../../store/app.reducer';
import * as LayoutActions from '../../layout/store/layout.actions';


@Directive({
  selector: '[appSmoothScroll]'
})
export class SmoothScrollDirective implements OnChanges {
  @Input('appSmoothScroll') scrollElement: ElementRef;

  scrollbar: Scrollbar;

  /**
   * directive to animate
   * items by fading them in
   */
  hasScroll() {
    // return false;
    if(!this.elementRef
      || !this.elementRef.nativeElement) {
      return;
    }

    const elem = this.elementRef.nativeElement.querySelector("#content-scroll") as HTMLElement;
console.log(elem)
    // this.scrollbar = Scrollbar.init(elem,
    // {
    //   renderByPixels: true,
    //   damping:0.05
    // });
    this.store.dispatch(new LayoutActions.ApplySmoothScroll({ scrollbar: this.scrollbar }));
  }

  ngOnChanges() {
    this.hasScroll();
  }

  constructor(
    private elementRef: ElementRef,
    private store: Store<fromAppReducer.AppState>
  ) {
    
  }
}
