import { Action } from '@ngrx/store';
import Scrollbar from 'smooth-scrollbar';

export const APPLY_SMOOTH_SCROLL = '[Layout] APPLY_SMOOTH_SCROLL';
export const CONTENT_PRELOADED = '[Layout] CONTENT_PRELOADED';

export class ApplySmoothScroll implements Action {
  readonly type = APPLY_SMOOTH_SCROLL;
  constructor(public payload: { scrollbar: Scrollbar }) {}
}

export class ContentPreloaded implements Action {
  readonly type = CONTENT_PRELOADED;
  constructor(public payload: { state: boolean }) {}
}


export type LayoutActions = ApplySmoothScroll | ContentPreloaded;