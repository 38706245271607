import { User } from '../user/user.model';
import { OrderItem } from './order-item.model';

export class Order {
  constructor(
    public id: number,
    public createdBy: User,
    public createdOn: Date,
    public modifiedBy: User,
    public modifiedOn: Date,
    public comment: string,
    public orderItems: OrderItem[]
  ) {}
}