import { Component, OnInit } from '@angular/core';
import { CookieService } from '../cookie.service';
import { ConfigurationModel } from '../configuration.model';
import { isScullyRunning } from '@scullyio/ng-lib';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  informationOpened = false;
  dialogOpen = true;
  cookiesDenied = true;
  scullyRunning: boolean;
  configuration;

  constructor(private cookieService: CookieService) {
    this.configuration = new ConfigurationModel();
  }

  ngOnInit() {
    this.cookiesDenied = this.cookieService.state.cookiesDenied;
    if(this.cookieService.state.dialogInteraction) {
      // close dialog by default if user acted already
      this.dialogOpen = false;
    }
    this.scullyRunning = isScullyRunning()
  }

  /**
   * call action to deny
   * all cookies
   */
  deny() {
    this.cookiesDenied = true;
    this.cookieService.denyCookies();
    this.closeDialog()
  }

  /**
   * call action to allow
   * cookies
   */
  accept() {
    this.cookiesDenied = false;
    this.cookieService.acceptCookies();
    this.closeDialog()
  }

  toggleInformation() {
    // toggle open info
    this.informationOpened = !this.informationOpened;
  }

  openDialog() {
    this.dialogOpen = true;
  }

  closeDialog() {
    this.dialogOpen = false;

  }
}
