import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as OrderActions from './order.actions';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DirectusService } from '../../core/directus.service';
import * as fromApp from '../../store/app.reducer';
import { Order } from '../order.model';
import { Category } from '../../category/category.model';

@Injectable()
export class OrderEffects {
  
  fetchOrders = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.FETCH_ORDERS),
    switchMap(() => {
      const client = this.directus.getClient();
      return from(client.items('orders').readByQuery({
                              fields: '*,brand.*,gallery_images.directus_files_id.*',
                              filter: {
                                can_be_ordered: {
                                  '_eq': 0
                                }
                              }}));
    }),
    map((orders: Order[]) => {
      return new OrderActions.SetOrders({orders});
    })
  ));

  
  applyOrder = createEffect(() => this.actions$.pipe(
    ofType(OrderActions.APPLY_ORDER),
    switchMap((applyOrderAction: OrderActions.ApplyOrder) => {
      const client = this.directus.getClient();
      let payload = applyOrderAction.payload;

      return from(client.transport.post('/order', payload)).pipe(
        map(result => {
          // dispatch action to set contact as received
          return new OrderActions.OrderSent();
        }),
        catchError(error => {
          return of(new OrderActions.OrderFailed([error.message]));
        })
      );

    })
  ));

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>) {}
}