import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from '../layout/app-layout/app-layout.component';
import { DetailComponent } from './detail/detail.component';
import { ProductResolverService } from './product-resolver.service';

const ProductRoutes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: 'products', redirectTo: '/product/list', pathMatch: 'full' },
      {
        path: 'product/:product',
        component: DetailComponent,
        resolve: [ProductResolverService],
        data: {
          pageTitle: 'Eine Auswahl unserer Produkte',
          animation: ''
        }
      }
    ],
    data: {
      animation: 'App',
      pageTitle: 'Produkt'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(ProductRoutes)],
  exports: [RouterModule]
})
export class ProductRoutingModule {}
