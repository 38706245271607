import { Action } from '@ngrx/store';
import { File } from '../file.model';

export const UPLOAD_FILE = '[File] UPLOAD_FILE';
export const FILE_UPLOADED = '[File] FILE_UPLOADED';
export const FILE_FAILED = '[File] FILE_FAILED';

export class UploadFile implements Action {
  readonly type = UPLOAD_FILE;
  constructor(public payload: File) {}
}

export class FileUploaded implements Action {
  readonly type = FILE_UPLOADED;
  constructor(public payload: any) {}
}

export class FileUploadFailed implements Action {
  readonly type = FILE_FAILED;
  constructor(public payload: string[]) {}
}

export type FileActions = UploadFile | FileUploaded | FileUploadFailed;
