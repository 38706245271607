import { Brand } from "../brand/brand.model";
import { Product } from "./product.model";

export class Landing {
  constructor(
    public id: number,
    public status: string,
    public uri: string,
    public title: string,
    public subtitle: string,
    public description: string,
    public section: any,
    public altImage: any,
    public image: any,
    public brand: Brand,
    public product: Product,
    public menuLabel: string
  ) {}
}
