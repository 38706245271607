import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from '../layout/app-layout/app-layout.component';
import { ListComponent } from './list/list.component';
import { BrandResolverService } from './brand-resolver.service';

const BrandRoutes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: '/brand/list', pathMatch: 'full' },
      {
        path: 'brand/list',
        component: ListComponent,
        resolve: [BrandResolverService],
        data: {
          pageTitle: 'List all Brands',
          animation: ''
        }
      }
    ],
    data: {
      animation: 'App',
      pageTitle: 'Edit Brand'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(BrandRoutes)],
  exports: [RouterModule]
})
export class BrandRoutingModule {}
