import { Component, OnInit } from '@angular/core';
import { Block } from '../../shared/cms/block.model';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  privacyContentBlockIdentifier: string = 'privacy_content';
  content: Block;

  constructor(
    private store: Store<fromApp.AppState>
  ) { }

  ngOnInit() {
    this.store.select('block').pipe(
      take(1),
      map(store => {
        const filtered = store.blocks.filter(block => block.identifier === this.privacyContentBlockIdentifier);
        if(filtered) {
          return filtered[0];
        }
        return null;
      })
    ).subscribe(block => {
      this.content = block;
    });
  }

}
