import Scrollbar from 'smooth-scrollbar';
import * as LayoutActions from './layout.actions';

export interface State {
  scrollbar: Scrollbar,
  initPreloaded: boolean
}

const initialState: State = {
  scrollbar: null,
  initPreloaded: false
}

export function fromLayoutReducer(
  state: State,
  action: LayoutActions.LayoutActions
) {
  switch(action.type) {
    case LayoutActions.APPLY_SMOOTH_SCROLL:
      return {
        ...state,
        scrollbar: action.payload.scrollbar
      };
    case LayoutActions.CONTENT_PRELOADED:
      return {
        ...state,
        initPreloaded: action.payload.state
      }
    default:
      state;
  }
}