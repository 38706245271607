import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { take, exhaustMap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private store: Store<fromApp.AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select('auth').pipe(
      take(1),
      map(authState => {
        return authState.auth;
      }),
      exhaustMap(auth => {
        if (!auth) {
          return next.handle(req);
        }
        const modifiedReq = req.clone({
          headers: req.headers.set(
            'Authorization',
            'Authorization: Bearer ' + auth.token
          )
        });
        return next.handle(modifiedReq);
      })
    );
  }
}
