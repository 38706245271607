import { Institution } from './institution.enum';
import { DirectusStatus } from '../../core/directus-status.enum';

export class Contact {
  constructor(
    public id: number,
    public status: DirectusStatus,
    public subject: string,
    public email: string,
    public language: string,
    public institution: Institution,
    public page: string,
    public product: number,
    public pagename: string,
    public text: string,
    public additionalInfo: number|string, // file relation
    public created_on: string,
    public created_by: number
  ) {}
}
