import { User } from '../user/user.model';
import { Order } from './order.model';
import { Product } from '../product/product.model';

export class OrderItem {
  constructor(
    public id: number,
    public createdBy: User,
    public createdOn: Date,
    public modifiedBy: User,
    public modifiedOn: Date,
    public quantity: number,
    public article: Product,
    public order: Order
  ) {}
}