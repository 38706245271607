import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';
import { Subscription} from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Block } from 'src/app/shared/cms/block.model';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  blockSubscription: Subscription;
  imprintContentBlockIdentifier: string = 'about';
  content: Block;
  public jsonLD: SafeHtml;
  
  constructor(
    private store: Store<fromApp.AppState>,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.blockSubscription = this.store.select('block').pipe(
      map(store => {
        const filtered = store.blocks.filter(block => block.identifier === this.imprintContentBlockIdentifier);
        if(filtered) {
          return filtered[0];
        }
        return null;
      })
    ).subscribe(block => {
      this.content = block;
    });

    const json = 
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Vechelde, Germany",
        "postalCode": "38159",
        "streetAddress": "Hildesheimer Straße 11"
      },
      "email": "contact(at)wiesel-defence.de",
      "faxNumber": "+49 5302 5606",
      "member": [
        {
          "@type": "Organization"
        },
        {
          "@type": "Organization"
        }
      ],
      "name": "wiesel DEFENCE",
      "telephone": "+49 5302 5666"
    };
    this.jsonLD = this.getSafeHTML(json);
  }

  getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }


  ngOnDestroy() {
    if(this.blockSubscription) {
      this.blockSubscription.unsubscribe();
    }
  }

}
