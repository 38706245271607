import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderRoutingModule } from './order-routing.module';
import { SharedModule } from '../shared/shared.module';
import { OrderComponent, DialogImageContent } from './order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MatLegacyFormFieldModule as MatFormFieldModule
} from '@angular/material/legacy-form-field';
import {
  MatLegacyInputModule as MatInputModule,
} from '@angular/material/legacy-input';
import {
  MatLegacyRadioModule as MatRadioModule,
} from '@angular/material/legacy-radio';
import {
  MatLegacySelectModule as MatSelectModule,
} from '@angular/material/legacy-select';
import {
  MatLegacySnackBarModule as MatSnackBarModule,
} from '@angular/material/legacy-snack-bar';
import {
  MatStepperModule,
} from '@angular/material/stepper';
import {
  MatIconModule,
} from '@angular/material/icon';
import {
  MatLegacyTableModule as MatTableModule,
} from '@angular/material/legacy-table';
import {
  MatLegacyDialogModule as MatDialogModule
} from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [OrderComponent, DialogImageContent],
  // entryComponents: [DialogImageContent],
  imports: [
    CommonModule,
    OrderRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatSnackBarModule,
    MatStepperModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule
  ]
})
export class OrderModule { }
