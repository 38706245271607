export const environment = {
  production: true,
  api: {
    raw: 'https://wieseldefence.de/api/',
    url: 'https://wieseldefence.de/api/',
    assets: 'https://wieseldefence.de/api/assets/',
    project: '_'
  },
  logErrorsInProduction: true,
  defaultTokenExpireTime: 3600,
  linkToPGPPublicKey: './assets/public_AF5B1DF09D948DD51B1EA0C4E020721E0500747A.asc',
  messageNotification: {
    // if more than X equal messages are added, old ones are removed
    removeSameMessagesAfterDuplicates: 3,
    // if a message gets older than X seconds, it will be removed; 0 disables the function
    removeMessagesAfterSeconds: 30,
    // activates a close button on every message
    enableManualDismiss: true
  }
};