import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {Directus} from '@directus/sdk';

@Injectable({
  providedIn: 'root'
})
export class DirectusService {
  client: any;

  constructor() {}

  getClient() {
    if (!this.client) {
      this.initClient();
    }
    return this.client;
  }

  initClient() {
    this.client = new Directus(environment.api.url);
  }
}
