import { DirectusStatus } from '../../core/directus-status.enum';

export class Block {
  constructor(
    public id: string,
    public identifier: string,
    public createdOn: string,
    public status: DirectusStatus,
    public language: string,
    public title: string,
    public shortDescription: string,
    public content: string,
    public image: string
  ) {}
}