import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from '../store/auth.actions';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../core/error-handler/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  email: string;
  password: string;
  loading = false;
  authenticationError: string;
  authSub: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private router: Router
    ) {}

  ngOnInit() {
    this.authSub = this.store.select('auth').subscribe(auth => {
      if (auth.authError) {
        // show error message action
        this.notificationService.showError('Anmeldung fehlgeschlagen', auth.authError);
      }

      if(auth.auth?.token && !(auth.user?.tags && auth.user?.tags?.indexOf("steiner-access") !== -1)) {
        this.router.navigate(['/order']);
      }
    });
  }

  /**
   * action to login with
   * the given credentials from
   * the form
   */
  login() {
    if(this.email && this.password) {
      this.store.dispatch(
        new AuthActions.LoginStart({ email: this.email, password: this.password })
      );
    }
  }

  /**
   * action to clear up
   * error message
   */
  onHandleError() {
    this.store.dispatch(new AuthActions.ClearError());
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
