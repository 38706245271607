import { Action } from '@ngrx/store';
import { Productgroup } from '../productgroup.model';

export const FETCH_PRODUCTGROUPS = '[products] FETCH_PRODUCTGROUPS';
export const SET_PRODUCTGROUPS = '[products] SET_PRODUCTGROUPS';

export class FetchProductgroups implements Action {
  readonly type = FETCH_PRODUCTGROUPS;
}

export class SetProductgroups implements Action {
  readonly type = SET_PRODUCTGROUPS;

  constructor(public payload: {productgroups: Productgroup[]}) {}
}

export type ProductgroupActions = FetchProductgroups | SetProductgroups;