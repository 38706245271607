import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input, OnChanges} from '@angular/core';
import { TweenMax, TimelineLite, Power2 } from 'gsap';
import * as Swiper from 'swiper';
import * as $ from 'jquery';
import { blockReducer } from '../cms/store/block.reducer';

@Directive({
  selector: '[appShowcaseSlider]'
})
export class ShowcaseSliderDirective implements AfterViewInit {
  @Input('appShowcaseSlider') showcaseSliderElement: ElementRef;


  ngAfterViewInit() {
    this.initSliderInteractives();
  }

  constructor(private elementRef: ElementRef) {
    
  }

  initSliderInteractives() {
    const self = this;

    if(!this.elementRef
      || !this.elementRef.nativeElement) {
      return;
    }

    const swipers = this.elementRef.nativeElement.querySelectorAll('.swiper-slide');

    let titles = [];
    let subtitle = [];
    let counter = [];
    let part1 = [];
    let part2 = [];
    let part3 = [];
    
    if(swipers.length) {
      swipers.forEach((el, i) => {
        titles.push($(el).data('title'));
        subtitle.push($(el).data('subtitle'));
        counter.push($(el).data('number'));

        part1.push($(el).data('part-1'));
        part2.push($(el).data('part-2'));
        part3.push($(el).data('part-3'));
      })
      let interleaveOffset = 0.4;

      let swiperOptions = {
        direction: "horizontal",
        loop: false,
        grabCursor: true,
        resistance : true,
        resistanceRatio : 0,
        speed:1200,
        autoplay: false,
        effect: "slide",
        mousewheel: false,
        pagination: {
          el: '.showcase-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            let cont = "";
            if(part1[index] && part2[index] && part3[index]) {
              cont = '<div class="showcase-content"><div class="part-1"><h3>Situation</h3><span>' + part1[index] + '</span></div><div class="part-2"><h3>Lösung</h3><span>' + part2[index] + '</span></div><div class="part-3"><h3>Resultat</h3><span>' + part3[index] + '</span></div></div>' ;
            cont = '<div class="tab__link ' + className + '"><div class="counter-wrap">' + '<div style="bottom:-35px;" class="counter">' + counter[index] + '</div>' + '</div>' + '<div class="subtitle">' + subtitle[index] + '</div>' + '<div class="title">' + titles[index] + '</div>' + '' + cont  + '</div>';
            } else {
              cont = '<div class="tab__link ' + className + '"><div class="counter-wrap">' + '<div class="counter">' + counter[index] + '</div>' + '</div>' + '<div class="subtitle">' + subtitle[index] + '</div>' + '<div class="title">' + titles[index] + '</div>' + '' + cont  + '</div>';
            }
            return cont;
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          progress: function() {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".img-mask").style.transform = "translate3d(" + innerTranslate + "px,0, 0)";
            }
          },
          touchStart: function() {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function(speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + "ms";
            swiper.slides[i].querySelector(".img-mask").style.transition = speed + "ms";
            }   
          },
          init: function () {
            /*
            $('.swiper-slide-active').find('video').each(function() {
              $(this).get(0).play();
            });
            */
          },
          slideNextTransitionStart: () => {
            //TweenMax.set($(".swiper-slide"), {transform:"skew(10deg, 0deg)"});						
            var prevslidetitle = new TimelineLite();
            prevslidetitle.staggerTo($('.swiper-pagination-bullet-active').prev().find('.title span'), 0.5, {scale:0.9, x:-100, opacity:0, ease:Power2.easeInOut},  0.02)
            var prevslidecaption = new TimelineLite();
            prevslidecaption.staggerTo($('.swiper-pagination-bullet-active').prev().find('.subtitle'), 0.5, {x:-20, opacity:0, delay:0.3, ease:Power2.easeIn},  0.02)
            // additional content for special swiper 
            var prevslidecontent = new TimelineLite();
            prevslidecontent.staggerTo($('.swiper-pagination-bullet-active').prev().find('.showcase-content'), 0.5, {x:-20, opacity:0, delay:0.3, ease:Power2.easeIn},  0.02)
            
            var activeslidetitle = new TimelineLite();
            activeslidetitle.staggerTo($('.swiper-pagination-bullet-active').find('.title span'), 0.5, {scale:1, x:0, opacity:1, delay:0.3, ease:Power2.easeOut}, 0.02)
            var activeslidecaption = new TimelineLite();
            activeslidecaption.staggerTo($('.swiper-pagination-bullet-active').find('.subtitle'), 0.5, {x:0, opacity:1, scale:1, delay:0.6, ease:Power2.easeOut}, 0.02)							
            // additional content for special swiper 
            var activeslidecontent = new TimelineLite();
            activeslidecontent.staggerTo($('.swiper-pagination-bullet-active').find('.showcase-content'), 0.5, {x:0, opacity:1, scale:1, delay:0.6, ease:Power2.easeOut}, 0.02)							
            
            var nextslidetitle = new TimelineLite();
            nextslidetitle.staggerTo($('.swiper-pagination-bullet-active').next().find('.title span'), 0.5, {scale:1.1, x:100, opacity:0, ease:Power2.easeInOut},  0.02)
            var nextslidecaption = new TimelineLite();
            nextslidecaption.staggerTo($('.swiper-pagination-bullet-active').next().find('.subtitle'), 0.5, {x:20, opacity:0, delay:0.3, ease:Power2.easeIn},  0.02)
            // additional content for special swiper 
            var nextslidecontent = new TimelineLite();
            nextslidecontent.staggerTo($('.swiper-pagination-bullet-active').next().find('.showcase-content'), 0.5, {x:20, opacity:0, delay:0.3, ease:Power2.easeIn},  0.02)

            var tl = new TimelineLite();

            
            $('.swiper-pagination-bullet-active').prev().find('.counter').each(function(index, element) {
              tl.to(element, 0.3, {scale:1, y:-20, opacity:0, ease:Power2.easeIn}, index * 0.01)
            });
            
            $('.swiper-pagination-bullet-active').find('.counter').each(function(index, element) {
              tl.to(element, 0.4, {scale:1, y:0, opacity:1, delay:0.3, ease:Power2.easeOut}, index * 0.01)
            });
            
            $('.swiper-pagination-bullet-active').next().find('.counter').each(function(index, element) {
              tl.to(element, 0.3, {scale:1, y:20, opacity:0, ease:Power2.easeIn}, index * 0.01)
            });
                        
          },
          slidePrevTransitionStart: function () {	
            
            //TweenMax.set($(".swiper-slide"), {transform:"skew(-10deg, 0deg)"});
            
            var prevslidetitle = new TimelineLite();
            prevslidetitle.staggerTo($('.swiper-pagination-bullet-active').prev().find('.title span'), 0.5, {scale:1.1, x:-100, opacity:0, ease:Power2.easeInOut},  -0.02)
            var prevslidecaption = new TimelineLite();
            prevslidecaption.staggerTo($('.swiper-pagination-bullet-active').prev().find('.subtitle'), 0.5, {x:-20, opacity:0, delay:0.3, ease:Power2.easeIn},  -0.02)
            // additional content for special swiper 
            var prevslidecontent = new TimelineLite();
            prevslidecontent.staggerTo($('.swiper-pagination-bullet-active').prev().find('.showcase-content'), 0.5, {x:-20, opacity:0, delay:0.3, ease:Power2.easeIn},  -0.02)
            
            var activeslidetitle = new TimelineLite();
            activeslidetitle.staggerTo($('.swiper-pagination-bullet-active').find('.title span'), 0.5, {scale:1, x:0, opacity:1, delay:0.5, ease:Power2.easeOut}, -0.02)
            var activeslidecaption = new TimelineLite();
            activeslidecaption.staggerTo($('.swiper-pagination-bullet-active').find('.subtitle'), 0.5, {x:0, opacity:1, scale:1, delay:0.6, ease:Power2.easeOut}, -0.02)							
            // additional content for special swiper 
            var activeslidecontent = new TimelineLite();
            activeslidecontent.staggerTo($('.swiper-pagination-bullet-active').find('.showcase-content'), 0.5, {x:0, opacity:1, scale:1, delay:0.6, ease:Power2.easeOut}, -0.02)							
            
            var nextslidetitle = new TimelineLite();
            nextslidetitle.staggerTo($('.swiper-pagination-bullet-active').next().find('.title span'), 0.5, {scale:0.9, x:100, opacity:0, ease:Power2.easeInOut},  -0.02)
            var nextslidecaption = new TimelineLite();
            nextslidecaption.staggerTo($('.swiper-pagination-bullet-active').next().find('.subtitle'), 0.5, {x:20, opacity:0, delay:0.3, ease:Power2.easeIn},  -0.02)
            // additional content for special swiper 
            var nextslidecontent = new TimelineLite();
            nextslidecontent.staggerTo($('.swiper-pagination-bullet-active').next().find('.showcase-content'), 0.5, {x:20, opacity:0, delay:0.3, ease:Power2.easeIn},  -0.02)
            
            
            var tl = new TimelineLite();
            
            $('.swiper-pagination-bullet-active').prev().find('.counter').each(function(index, element) {
              tl.to(element, 0.3, {scale:1, y:-20, opacity:0, delay:0.1,  ease:Power2.easeIn}, index * 0.01)
            });
            
            $('.swiper-pagination-bullet-active').find('.counter').each(function(index, element) {
              tl.to(element, 0.4, {scale:1, y:0, opacity:1, delay:0.45, ease:Power2.easeOut}, index * 0.01)
            });
            
            $('.swiper-pagination-bullet-active').next().find('.counter').each(function(index, element) {
              tl.to(element, 0.3, {scale:1, y:20, opacity:0, delay:0.1,  ease:Power2.easeIn}, index * 0.01)
            });	
                
          },
          slideChangeTransitionStart: function () {
            
            //TweenMax.to($(".swiper-slide"), 0.3, {force3D:true, transform:"skew(0deg, 0deg)", delay:0.2, ease:Power2.easeOut});
            
            self.linesWidth();
            
            $('.swiper-slide-active').find('video').each(function() {
              /*
              $(this).get(0).play();
              */
            });
            
          },
          slideChangeTransitionEnd: function () {
            
            
            
            $('.swiper-slide-prev').find('video').each(function() {
              /*$(this).get(0).pause();*/
            });
            
            $('.swiper-slide-next').find('video').each(function() {
              /*$(this).get(0).pause();*/
            });
            
          }
        },
      };
    
      var swiper = new Swiper.default(".swiper-container", swiperOptions);
      
      self.linesWidth();
      
      $('.title').each(function(){
        var words = $(this).text().split(" ");
        var total = words.length;
        $(this).empty();
        for (let index = 0; index < total; index ++){
          $(this).append($("<span /> ").text(words[index])).append($("<span style='display:inline-block; width: 10px;'/> "));
        }
      });
    }
  }

  linesWidth() {
    let lineWidth;
    var carouselWidth = $('#showcase-holder').width();
    var captionWidth = $('.swiper-pagination-bullet-active .title').width();
    if ($(window).width() >= 1466) {
        lineWidth = carouselWidth / 2 - 440
    } else if ($(window).width() >= 1024) {
        lineWidth = carouselWidth / 2 - 220
    } else if ($(window).width() >= 767) {
        lineWidth = carouselWidth / 2 - 160
    } else if ($(window).width() >= 479) {
        lineWidth = carouselWidth / 2 - 50
    } else {
        lineWidth = carouselWidth / 2 - 40
    }
            
    $(".caption-border.left").css({
      'width': lineWidth - captionWidth/2 + 'px',
      'opacity': 1,
    });
    $(".caption-border.right").css({
      'width': lineWidth - captionWidth/2 + 'px',
      'opacity': 1,
    });
  }
}
