import { DirectusStatus } from '../../core/directus-status.enum';

export class Newsticker {
  constructor(
    public id: string,
    public createdOn: string,
    public status: DirectusStatus,
    public language: string,
    public title: string,
    public shortDescription: string,
    public content: string,
    public images: string[],
    public mainImage: string
  ) {}
}