import { Action } from '@ngrx/store';
import { Block } from '../block.model';

export const SET_BLOCKS = '[Block] SET_BLOCKS';
export const FETCH_BLOCKS = '[Block] FETCH_BLOCKS';


export class FetchBlocks implements Action {
    readonly type = FETCH_BLOCKS;
  }
  
  export class SetBlocks implements Action {
    readonly type = SET_BLOCKS;
  
    constructor(public payload: {blocks: Block[]}) {}
  }
  
  export type BlockActions = FetchBlocks | SetBlocks;