import * as NewsletterActions from './newsletter.actions';

export interface State {
  newsletterSubscriptionSubmitted: boolean;
  newsletterSubscriptionSent: boolean;
  newsletterSubscriptionFailed: string[];
}

const initialState: State = {
  newsletterSubscriptionSubmitted: false,
  newsletterSubscriptionSent: false,
  newsletterSubscriptionFailed: []
};

export function newsletterReducer(
  state: State = initialState,
  action: NewsletterActions.NewsletterActions
) {
  switch (action.type) {
    case NewsletterActions.SUBMIT_NEWSLETTER_SUBSCRIPTION:
      return {
        ...state,
        newsletterSubscriptionSubmitted: true
      };
    case NewsletterActions.NEWSLETTER_SUBSCRIPTION_SENT:
      return {
        ...state,
        newsletterSubscriptionSent: true,
        newsletterSubscriptionSubmitted: false
      };
    case NewsletterActions.NEWSLETTER_SUBSCRIPTION_FAILED:
      return {
        ...state,
        newsletterSubscriptionSubmitted: false,
        newsletterSubscriptionFailed: [...action.payload.messages]
      };
    default:
      return state;
  }
}
