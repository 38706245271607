import * as ProductgroupActions from './productgroup.actions';
import { Productgroup } from '../productgroup.model';

export interface State {
  productgroups: Productgroup[];
}

export const initialState: State = {
  productgroups: []
}

export function productgroupReducer(
  state: State = initialState,
  action: ProductgroupActions.ProductgroupActions
) {
  switch(action.type) {
    case ProductgroupActions.SET_PRODUCTGROUPS:
      return {
        ...state,
        productgroups: action.payload.productgroups
      };
    default:
      return state;
  }
}