import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { AppRoutingService } from './app-routing.service';

@NgModule({
  declarations: [],
  providers: [AppRoutingService],
  imports: [CommonModule, MatSnackBarModule]
})
export class CoreModule {}
