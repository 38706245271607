import { Product } from './product.model';
import { Category } from '../category/category.model';

export class ProductHelper {
  constructor() {

  }

  /**
   * this method will return a new
   * array with objects of category and products
   * that are resorted by the categories
   * in the product collection
   */
  static sortProductsByCategories(products: Product[]) {
    let productsByCategories: {category: Category, products: Product[]}[] = [];
    let availableCategories: Category[] = [];
    // reorder products to sort by categories
    products.forEach((product: Product, index: number) => {
      if(product.category) {
        if(availableCategories.indexOf(product.category) !== -1) {
          // add it to the productsByCategories
          productsByCategories.forEach((item, ind) => {
            if(item.category === product.category) {
              productsByCategories[ind].products.push(product);
            }
          });
        } else {
          // add new item
          productsByCategories.push({
            category: product.category,
            products: [product]
          });
          availableCategories.push(product.category);
        }
      }
    });

    return productsByCategories;
  }
}