import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromNewsticker from './store/newsticker.reducer';
import * as fromApp from '../../store/app.reducer';
import * as NewstickerActions from './store/newsticker.actions';
import { Newsticker } from './newsticker.model';
import { take } from 'rxjs/operators';
import { ngxLightOptions } from  'ngx-light-carousel/public-api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newsticker',
  templateUrl: './newsticker.component.html',
  styleUrls: ['./newsticker.component.scss']
})
export class NewstickerComponent implements OnInit {
  @Input('limit') limit: number = 100;
  news: Newsticker[];
  options: ngxLightOptions;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router
  ) {
    // construct the config object
    this.options = {
      scroll: {
        numberToScroll: 1
      },
      animation: {
          animationClass: 'transition',
          animationTime: 200,
      },
      swipe: {
          swipeable: true,
          swipeVelocity: 1,
      },
      drag: {
          draggable: true,
          dragMany: true,
      },
      infinite: false,
      autoplay: {
        enabled: true,
        direction: 'right',
        delay: 5000,
        stopOnHover: true,
      },
      breakpoints: [
        {
          width: 9999,
          number: 1
        }
      ],
  }
  }

  ngOnInit() {
    this.store.select('newsticker').pipe(take(1)).subscribe(store => {
      this.news = store.newstickers.slice(0, this.limit);
    });    
  }

  getDetails(newsItem) {
    this.router.navigate(['/news', newsItem.id]);
  }
}
