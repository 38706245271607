import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as BrandActions from './brand.actions';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { DirectusService } from '../../core/directus.service';
import * as fromApp from '../../store/app.reducer';
import { Brand } from '../brand.model';
import { FileImageModifier, HelperService } from '../../file/helper.service';

@Injectable()
export class BrandEffects {

  fetchBrands = createEffect(() => this.actions$.pipe(
    ofType(BrandActions.FETCH_BRANDS),
    switchMap(() => {
      const client = this.directus.getClient();
      return from(client.items('brands').readByQuery())
    }),
    map((brandsData: any) => {
      return brandsData.data.map(brand => {
        let logoPath = brand.logo ? this.fileHelper.getImage(brand.logo) : null;
        let headerPath = brand.header_image ? this.fileHelper.getImage(brand.header_image) : null;
        let altImagePath = brand.alt_image ? this.fileHelper.getImage(brand.alt_image, {
          fit: "cover",
          width: 900,
          height: 600,
          quality: 80,
        } as FileImageModifier) : null;
        const thumbString = this.fileHelper.getImage(brand.logo, {
          fit: "contain",
          width: 150,
          height: 150,
          quality: 70,
        } as FileImageModifier);

        return new Brand(
          brand.id,
          brand.title,
          brand.description,
          brand.content,
          brand.short_description,
          brand.claim,
          brand.language,
          brand.status,
          logoPath,
          thumbString,
          headerPath,
          altImagePath,
          brand.uri_identifier
        );
      });
    }),
    map((brands: Brand[]) => {
      return new BrandActions.SetBrands({ brands });
    })
  ));


  storeBrands = createEffect(() => this.actions$.pipe(
    ofType(BrandActions.STORE_BRAND),
    withLatestFrom(this.store.select('brand')),
    switchMap(([actionData, brandState]) => {
      /**
       * @todo check if this mechanism is ok for
       * saving changes
       */
      const client = this.directus.getClient();
      return from(client.updateItem('brands', brandState.editingBrandId, brandState.editingBrand));
    })
  ), { dispatch: false });

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>, private fileHelper: HelperService) { }
}