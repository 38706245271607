import { Action } from '@ngrx/store';
import { Order } from '../order.model';
import { OrderItem } from '../order-item.model';
import { Product } from '../../product/product.model';

export const FETCH_ORDERS = '[orders] FETCH_ORDERS';
export const SET_ORDERS = '[orders] SET_ORDERS';
export const ADD_PRODUCT = '[orders] ADD_PRODUCT';
export const REMOVE_PRODUCT = '[orders] REMOVE_PRODUCT';
export const CHANGE_QTY_FOR_PRODUCT = '[orders] CHANGE_QTY_FOR_PRODUCT';
export const APPLY_ORDER = '[orders] APPLY_ORDER';
export const ORDER_SENT = '[orders] ORDER_SENT';
export const ORDER_FAILED = '[orders] ORDER_FAILED';

export class FetchOrders implements Action {
  readonly type = FETCH_ORDERS;
}

export class SetOrders implements Action {
  readonly type = SET_ORDERS;

  constructor(public payload: {orders: Order[]}) {}
}

export class AddProduct implements Action {
  readonly type = ADD_PRODUCT;

  constructor(public payload: { product: Product, qty: number }) {}
}

export class RemoveProduct implements Action {
  readonly type = REMOVE_PRODUCT;

  constructor(public payload: {product: Product}) {}
}

export class ChangeQtyForProduct implements Action {
  readonly type = CHANGE_QTY_FOR_PRODUCT;

  constructor(public payload: { product: Product, qty: number }) {}
}

export class ApplyOrder implements Action {
  readonly type = APPLY_ORDER;

  constructor(public payload: Order) {}
}

export class OrderSent implements Action {
  readonly type = ORDER_SENT;
}

export class OrderFailed implements Action { 
  readonly type = ORDER_FAILED;

  constructor(public payload: string[]) {}
}

export type OrderActions = FetchOrders | SetOrders | AddProduct | RemoveProduct | ChangeQtyForProduct | ApplyOrder | OrderSent | OrderFailed;