import { User } from '../user/user.model';
import { Brand } from '../brand/brand.model';
import { Category } from  '../category/category.model';
import { Landing } from './landing.model';

export class Product {
  constructor(
    public id: number,
    public sku: string,
    public status: string,
    public productDetailsActive: boolean,
    public sort: number,
    public createdBy: User,
    public createdOn: Date,
    public title: string,
    public claim: string,
    public shortDescription: string,
    public description: string,
    public lang: string,
    public canBeOrdered: boolean,
    public brand: Brand,
    public mainImage: string,
    public headerTeaser: string,
    public galleryImages: string[],
    public uriIdentifier: string,
    public facts: string[],
    public category: Category,
    public video: string,
    public productListMode: string,
    public landing?: Landing
  ) {}
}
