import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from './error-handler.service';
import { NotificationService } from './notification.service';

@Injectable()
export class BasicErrorHandler implements ErrorHandler {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private notificationService: NotificationService
  ) {}

  handleError(error) {
    /**
     * parse the error
     */
    let title;
    let message;
    let stackTrace;
    if (error instanceof HttpErrorResponse) {
      // Server error
      message = this.errorHandlerService.getServerMessage(error);
      stackTrace = this.errorHandlerService.getServerStack(error);
      title = 'The server responded an error';
    } else {
      // Client Error
      message = this.errorHandlerService.getClientMessage(error);
      stackTrace = this.errorHandlerService.getClientStack(error);
      title = 'An error occured';
    }

    /**
     * return the error
     * depending on the environment
     */
    // handle the message to bring a
    // understandable error message to the user
    this.notificationService.showError(title, message);

    /**
     * if error in production is enabled, then
     * log the error
     */
    if (!environment.production || environment.logErrorsInProduction) {
      this.logError(title, message, stackTrace, error);
    }
  }

  /**
   * method to log errors to
   * the console or elsewhere
   */
  private logError(title: string, message: string, stackTrace, originalError) {
    console.error('User message:', title + ' |', message);
    console.log(originalError);
  }
}
