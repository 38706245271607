import * as BlockActions from './block.actions';
import { Block } from '../block.model';

export interface State {
  blocks: Block[];
}

export const initialState: State = {
  blocks: []
}

export function blockReducer(
  state: State = initialState,
  action: BlockActions.BlockActions
) {
  switch(action.type) {
    case BlockActions.SET_BLOCKS:
      return {
        ...state,
        blocks: action.payload.blocks
      };
    default:
      return state;
  }
}