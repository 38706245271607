import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as BlockActions from './block.actions';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { DirectusService } from '../../../core/directus.service';
import * as fromApp from '../../../store/app.reducer';
import { Block } from '../block.model';

@Injectable()
export class BlockEffects {
  
  fetchBlocks = createEffect(() => this.actions$.pipe(
    ofType(BlockActions.FETCH_BLOCKS),
    switchMap(() => {
      const client = this.directus.getClient();
      return from(client.items('content_blocks').readByQuery());
    }),
    map((blocksData: any) => {
      return blocksData.data.map(block => {
        return new Block(
            block.id,
            block.identifier,
            block.created_on,
            block.status,
            block.language,
            block.title,
            block.short_description,
            block.content,
            block.image
            );
      });
    }),
    map((blocks: Block[]) => {
      return new BlockActions.SetBlocks({blocks: blocks});
    })
  ));

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>) {}
}