import { Action } from '@ngrx/store';
import { NewsletterSubscription } from '../newsletter-subscription.model';

export const SUBMIT_NEWSLETTER_SUBSCRIPTION = '[newsletter] SUBMIT_NEWSLETTER_SUBSCRIPTION';
export const NEWSLETTER_SUBSCRIPTION_SENT = '[newsletter] NEWSLETTER_SUBSCRIPTION_SENT';
export const NEWSLETTER_SUBSCRIPTION_FAILED = '[newsletter] NEWSLETTER_SUBSCRIPTION_FAILED'

export class SubmitNewsletterSubscription implements Action {
  readonly type = SUBMIT_NEWSLETTER_SUBSCRIPTION;

  constructor(public payload: {newsletterSubscription: NewsletterSubscription}) {}
}

export class NewsletterSubscriptionSent implements Action {
  readonly type = NEWSLETTER_SUBSCRIPTION_SENT;
}

export class NewsletterSubscriptionFailed implements Action {
  readonly type = NEWSLETTER_SUBSCRIPTION_FAILED;

  constructor(public payload: {messages: string[]}) {}
}

export type NewsletterActions = SubmitNewsletterSubscription | NewsletterSubscriptionSent | NewsletterSubscriptionFailed;