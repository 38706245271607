import { Action } from '@ngrx/store';
import { User } from '../../user/user.model';

export const LOGIN_START = '[auth] Login Start';
export const AUTHENTICATE_SUCCESS = '[auth] Authenticate Success';
export const AUTHENTICATE_SENT = '[auth] Authenticate Sent';
export const AUTHENTICATE_FAIL = '[auth] Authenticate Fail';
export const CLEAR_ERROR = '[auth] Clear Error';
export const LOGOUT = '[auth] LOGOUT';
export const SIGNUP_START = '[auth] Signup Start';
export const AUTO_LOGIN = '[auth] Auto Login';
export const FETCH_USER = '[auth] Fetch User';
export const SEND_RESETPASSRESETREQ = '[auth] SEND_RESETPASSRESETREQ';
export const LOAD_USER = '[auth] Load User';
export const SET_USER = '[auth] Set User';

export class LoginStart implements Action {
  readonly type = LOGIN_START;
  
  constructor(public payload: { email: string; password: string }) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(
    public payload: { token: string; expirationDate: Date; redirect: boolean, refresh_token: string }
  ) {}
}

export class AuthenticateFail implements Action {
  readonly type = AUTHENTICATE_FAIL;

  constructor(public payload: { message: string }) {}
}

export class SendResetPassRequest implements Action {
  readonly type = SEND_RESETPASSRESETREQ;

  constructor(public payload: { email: string }) {}
}

export class AuthenticateSent implements Action {
  readonly type = AUTHENTICATE_SENT;
}

export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class SignupStart implements Action {
  readonly type = SIGNUP_START;

  constructor(
    public payload: {
      email: string;
      firstname: string;
      lastname: string;
      password: string;
      tags: string[]
    }
  ) {}
}

export class AutoLogin implements Action {
  readonly type = AUTO_LOGIN;
}

export class LoadUser implements Action {
  readonly type = LOAD_USER;
}

export class SetUser implements Action {
  readonly type = SET_USER;
  
  constructor(
    public payload: { user: User }
  ) {}
}

export class FetchUser implements Action {
  readonly type = FETCH_USER;

  constructor(
    public payload: { user: User }
  ) {}
}

export type AuthActions =
  | AuthenticateSuccess
  | Logout
  | LoginStart
  | AuthenticateFail
  | AuthenticateSent
  | SendResetPassRequest
  | ClearError
  | SignupStart
  | AutoLogin
  | FetchUser
  | LoadUser
  | SetUser;
