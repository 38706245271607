import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import * as fromAuth from '../store/auth.reducer';
import * as AuthActions from '../store/auth.actions';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  authActionsSubscription: Subscription;
  resetForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email])
  });
  requestSent = false;
  requestErrors: string[] = [];


  constructor(
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.authActionsSubscription = this.store.select('auth').subscribe(state => {
      this.requestSent = state.resetPassReqSent;
      if(state.authError) {
        this.requestErrors = [state.authError];
      }
    });
  }

  sendPasswordResetRequest() {
    this.store.dispatch(new AuthActions.SendResetPassRequest({ email: this.resetForm.controls.email.value }))
  }
}
