import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeSlideAnimation } from './slide.animations';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [routeSlideAnimation]
})
export class AppLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }
}
