import { Action } from '@ngrx/store';
import { Newsticker } from '../newsticker.model';

export const SET_NEWSTICKERS = '[Newsticker] SET_NEWSTICKERS';
export const FETCH_NEWSTICKERS = '[Newsticker] FETCH_NEWSTICKERS';


export class FetchNewstickers implements Action {
    readonly type = FETCH_NEWSTICKERS;
  }
  
  export class SetNewstickers implements Action {
    readonly type = SET_NEWSTICKERS;
  
    constructor(public payload: {newstickers: Newsticker[]}) {}
  }
  
  export type NewstickerActions = FetchNewstickers | SetNewstickers;