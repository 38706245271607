import { Component, OnInit, Inject } from '@angular/core';
import * as Swiper from 'swiper';
import ScrollMagic from 'scrollmagic';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
// import 'imports-loader?define=>false!../../animate.gsap.min.js';
import 'jquery.waitforimages';
import * as $ from 'jquery';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';
import { Block } from '../../shared/cms/block.model';
import { Brand } from '../../brand/brand.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  aboutContentBlock: Block;
  specialProductsUC: Block[] = [];
  aboutContentBlockIdentifier: string = 'about_home';
  specialProductUnobstrusiveItemBlockIdentifier: string[] = ['wabenfilter_inlet', 'laserfilter_inlet'];
  brands: Brand[];

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private metaService: Meta,
    @Inject(DOCUMENT) private doc
  ) {
    // also reload the component with lifecycle hooks on using history buttons from browser
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    // add canocial tag for the home duplicate
    if(this.router.url === "/") {
     let link: HTMLLinkElement = this.doc.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.doc.head.appendChild(link);
     link.setAttribute('href', "https://wieseldefence.de/home");
    }

    /**
     * fetch cms block for the "about-us" part
     */
    this.store.select('block').pipe(
      take(1)
    ).subscribe(store => {

        const filteredAbout = store.blocks.filter(block => block.identifier === this.aboutContentBlockIdentifier);
        if(filteredAbout) {
          this.aboutContentBlock = filteredAbout[0];
        }

        const filteredSpecial = store.blocks.filter(block => this.specialProductUnobstrusiveItemBlockIdentifier.indexOf(block.identifier) !== -1);
        if(filteredSpecial) {
          this.specialProductsUC = filteredSpecial
        }
    });

    /**
     * fetch brands for the home page
     */
    this.store.select('brand').pipe(
      take(1),
      map(store => {
        return store.brands;
      })
    ).subscribe(brands => {
      this.brands = brands;
    });
  }
}
