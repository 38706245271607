import * as ProductActions from './product.actions';
import { Product } from '../product.model';
import { Landing } from '../landing.model';

export interface State {
  products: Product[];
  orderableProducts: Product[];
  currentLoadLanding: string,
  landing: Landing
}

export const initialState: State = {
  products: [],
  orderableProducts: [],
  currentLoadLanding: null,
  landing: null,
}

export function productReducer(
  state: State = initialState,
  action: ProductActions.ProductActions
) {
  switch(action.type) {
    case ProductActions.SET_PRODUCTS:
      return {
        ...state,
        products: [...action.payload.products]
      };
    case ProductActions.SET_ORDERABLE_PRODUCTS:
      return {
        ...state,
        orderableProducts: [...action.payload.products]
      };
    case ProductActions.FETCH_LANDING:
      return {
        ...state,
        currentLoadLanding: action.payload.landingSlug
      };
    case ProductActions.SET_LANDING:

console.log(action.payload.landing)
      return {
        ...state,
        landing: { ...action.payload.landing }
      }
    default:
      return state;
  }
}