import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import { NotificationService } from '../core/error-handler/notification.service';
import * as AuthActions from './store/auth.actions';
import { Auth } from '../auth/auth.model';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  public loginAuthTokenObservable;
  private tokenExpirationTimer: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService
  ) {}

  /**
   * automatically logout and clear
   * session to redirect the user to
   * the authentication after a
   * token has been expired
   */
  setLogoutTimer(expirationDuration: number) {
    // this.tokenExpirationTimer = setTimeout(() => {
    //   this.store.dispatch(new AuthActions.Logout());
    //   // inform user about session expiration
    //   this.notificationService.showInfo('Session abgelaufen', 'Ihre Session ist abgelaufen, bitte loggen Sie sich erneut ein.')
    // }, expirationDuration);
  }

  clearLogouttimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
      this.tokenExpirationTimer = null;
    }
  }

  ngOnDestroy() {}
}
