import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { concatMap, exhaustMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {Directus} from '@directus/sdk';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of, pipe, from, interval, Observable } from 'rxjs';
import { Auth } from '../auth.model';
import { User } from '../../user/user.model';
import { AuthService } from '../auth.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { NotificationService } from 'src/app/core/error-handler/notification.service';

const handleAuthentication = (token: string, refresh_token: string, expires?: number) => {
  const expiresIn = expires - 1000;
  const expirationDate = new Date(new Date().getTime() + expiresIn);
  const auth = new Auth(token, expirationDate, refresh_token);

  // save auth data to the local storage
  localStorage.setItem('authData', JSON.stringify(auth));

  return new AuthActions.AuthenticateSuccess({
    token,
    expirationDate,
    refresh_token,
    redirect: true
  });
};

const handleError = (errorRes: any) => {
  let errorMessage = 'An unknown error occured!';
  
  if (errorRes.message) {
    // handle here, like translate the messages from directus
    switch(errorRes.code) {
      case 103:
        errorMessage = 'Anmeldung fehlerhaft';
        break;
      default:
        errorMessage = 'Bitte versuchen Sie es erneut';
    }

    if(errorRes.message === "Your account is suspended due to maximum allowed login attempts. Please contact your administrator to activate your account.") {
      errorMessage = 'Sie haben die maximal erlaubten Login-Versuche erreicht. Der Zugriff wird blockiert. Bitte kontaktieren Sie uns, um Ihren Account wieder zu aktivieren.';
    }
  }
  return of(new AuthActions.AuthenticateFail({ message: errorMessage }));
};

@Injectable()
export class AuthEffects {
  
  authLogin = createEffect(() => this.actions$.pipe(
    // trigger effect only on login start
    ofType(AuthActions.LOGIN_START),
    switchMap((authData: AuthActions.LoginStart) => {
      const client = new Directus(environment.api.url, {
          auth: {
            mode: 'json',
            autoRefresh: false,
            // msRefreshBeforeExpires: 30000,
            // staticToken: '',
          },
          storage: {
            prefix: '',
            mode: 'LocalStorage', // 'MemoryStorage' in Node.js
          },
          transport: {
            params: {},
            headers: {},
            onUploadProgress: (ProgressEvent) => {},
            maxBodyLength: null,
            maxContentLength: null,
          },
        }
      );


      return from(
        client.auth.login({
          email: authData.payload.email,
          password: authData.payload.password
        })
      ).pipe(
        tap(async (resData) => {
          const expiresIn = Date.now() + resData.expires;
          this.authService.setLogoutTimer(expiresIn);
        }),
        map((resData) => {
          return handleAuthentication(resData.access_token, resData.refresh_token, resData.expires);
        }),
        catchError(error => {
          return handleError(error);
        })
      );
    })
  ));

  
  authRedirect = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AUTHENTICATE_SUCCESS),
    switchMap((authSuccessAction: AuthActions.AuthenticateSuccess) => {
      const client = new Directus(environment.api.url);
      return from(client.users.me.read());
    }),
    map((result: any) => {
      let user = new User(
        result.id,
        result.email,
        result.email,
        result.first_name,
        result.last_name,
        null,
        result.locale,
        result.tags
      );
      
      localStorage.setItem('userData', JSON.stringify(user));
      return user;
    }),
    tap((a: User) => {
      if(a.tags && a.tags.indexOf("steiner-access") !== -1) {
        this.notificationService.showInfo('Erfolgreich eingeloggt', 'Sie haben Zugriff auf den Steiner Hersteller-Bereich.');
        this.router.navigate(['/']);
      } else {
        this.notificationService.showInfo('Erfolgreich eingeloggt', 'Sie haben Zugriff den EOD Verbrauchsmaterial Bereich.');
        this.router.navigate(['/order']);
      }
    })
  ), { dispatch: false });

  
  authLogout = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    tap(async () => {
      const client = new Directus(environment.api.url);
      // currently not using this because of directus error when loggin out due to refresh_token
      // await client.auth.logout();

      // remove token
      this.authService.clearLogouttimer();
      localStorage.removeItem('authData');
      localStorage.removeItem('userData');
      this.router.navigate(['/login']);
    })
  ), { dispatch: false });

  
  authSignup = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.SIGNUP_START),
    switchMap((signupAction: AuthActions.SignupStart) => {
      const client = new Directus(environment.api.url);

      return from(
        client.transport.post('/auth/register', {
          email: signupAction.payload.email,
          first_name: signupAction.payload.firstname,
          last_name: signupAction.payload.lastname,
          password: signupAction.payload.password,
          tags: signupAction.payload.tags
        })
      );
    }),
    map(resData => {
      return new AuthActions.AuthenticateSent();
    })
  ));

  
  autoLogin = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AUTO_LOGIN),
    map(() => {
      const authData = JSON.parse(localStorage.getItem('authData'));
      if (!authData) {
        return { type: 'DUMMY' };
      }

      const currentAuth = new Auth(
        authData._token,
        new Date(authData._tokenExpireDate),
        authData.refresh_token
      );

      if (currentAuth.token) {
        const expirationDuration =
          new Date(authData._tokenExpireDate).getTime() - new Date().getTime();
        this.authService.setLogoutTimer(expirationDuration);
        const expirationDate = new Date(new Date().getTime() + expirationDuration);
        return new AuthActions.AuthenticateSuccess({
          token: currentAuth.token,
          expirationDate,
          refresh_token: authData.refresh_token,
          redirect: false
        });
      }
      return { type: 'DUMMY' };
    })
  ));

  
  fetchUser = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AUTHENTICATE_SUCCESS),
    switchMap((successAction) => {
      let storedUser = localStorage.getItem('userData')
      if(!storedUser || storedUser == "null" || storedUser === null) {
        const client = new Directus(environment.api.url);
        return from(client.users.me.read());
      } else {
        return of(JSON.parse(localStorage.getItem('userData')));
      }
    }),
    map(result => {
      if(result.data && result.data.id !== undefined) {
        let user = new User(
          result.data.id,
          result.data.email,
          result.data.email,
          result.data.first_name,
          result.data.last_name,
          null,
          result.data.locale,
          result.tags
        );
        
        localStorage.setItem('userData', JSON.stringify(result));
        return user;
      }
      return null;
    }),
    map((user: User) => {
      if(user === null) {
        return { type: 'DUMMY'};
      }
      return new AuthActions.FetchUser({ user: user });
    })
  ));

  
  sentResetPassReq = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.SEND_RESETPASSRESETREQ),
    switchMap((resetReqAction: AuthActions.SendResetPassRequest) => {
      const client = new Directus(environment.api.url);
      return from(client.auth.password.request(resetReqAction.payload.email));
    })
  ), {dispatch: false});


  loadUser = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOAD_USER),
    switchMap((resetReqAction: AuthActions.LoadUser) => {
      const client = new Directus(environment.api.url);
      return from(client.users.me.read());
    }),
    map((result: any) => {
      let user = new User(
        result.id,
        result.email,
        result.email,
        result.first_name,
        result.last_name,
        null,
        result.locale,
        result.tags
      );
      
      localStorage.setItem('userData', JSON.stringify(user));
      return new AuthActions.SetUser({ user: user });
    })
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
  ) {}
}
