import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as NewsletterActions from './newsletter.actions';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { DirectusService } from '../../../core/directus.service';
import * as fromApp from '../../../store/app.reducer';
import { NewsletterSubscription, Recipient } from '../newsletter-subscription.model';

@Injectable()
export class NewsletterEffects {
  
  newsletterSubscriptionSubmit = createEffect(() => this.actions$.pipe(
    ofType(NewsletterActions.SUBMIT_NEWSLETTER_SUBSCRIPTION),
    switchMap((newsletterAction: NewsletterActions.SubmitNewsletterSubscription) => {
      const client = this.directus.getClient();
      let payload = newsletterAction.payload.newsletterSubscription.recipient;
      return from(client.transport.post('custom/newsletter', payload)).pipe(
        map(result => {
          // dispatch action to set contact as received
          return new NewsletterActions.NewsletterSubscriptionSent();
        }),
        catchError(error => {
          return of(new NewsletterActions.NewsletterSubscriptionFailed({ messages: [error.message]}));
        })
      );
    })
  ));

  constructor(private actions$: Actions, private directus: DirectusService, private store: Store<fromApp.AppState>) {}
}