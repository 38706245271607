import { ErrorType } from './error-type.enum';

export class Message {
  constructor(
    public type: ErrorType,
    public title: string,
    public message: string,
    public link: string,
    public born: Date,
    public snackbarRef?
  ) {}
}
