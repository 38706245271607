import { Component, OnInit, Inject, ChangeDetectorRef, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Product } from 'src/app/product/product.model';
import { Landing } from 'src/app/product/landing.model';
import { Brand } from 'src/app/brand/brand.model';
import { HelperService } from 'src/app/file/helper.service';
import { ViewportScroller } from '@angular/common';
import { TweenMax, TimelineLite, Power2 } from 'gsap';
import * as $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'dialog-contact-byary-content',
  template: `
    <mat-dialog-content>
      <div>
        <mat-icon mat-dialog-close aria-hidden="false" aria-label="einklappen" [ngClass]="'clickable close-icon'">close</mat-icon>
        <div class="popup-contact">
          <app-contact-form [prefilledMessage]="defaultMessage"></app-contact-form>
        </div>
      </div>
    </mat-dialog-content>
  `,
})
export class DialogContactByArxContent {
  defaultMessage: string = '';

  constructor(
    public dialogRef?: MatDialogRef<DialogContactByArxContent>,
    @Inject(MAT_DIALOG_DATA) public data?) {
    this.defaultMessage = `Guten Tag,
ich interessiere mich für das Produkt "` + data.product.title + `".
Bitte senden Sie mir dazu folgende Informationen:
 - ...
 - ...

Vielen Dank!
    `
  }
}


@Component({
  selector: 'app-landing-serstech-arx',
  templateUrl: './serstech-arx.component.html',
  styleUrls: ['./serstech-arx.component.scss']
})
export class SerstechArxComponent implements OnInit {
  private _landing: Landing;
  scrollbar: Scrollbar;
  @Input() set landing(landing: Landing) {
    // pass landing to protected
    this._landing = landing;

    // fill ses
    this.chemThreatSec = this.getSectionByKey("chemische-gefahrenerkennung");
    this.videoIntroSec = this.getSectionByKey("konkurrenzlos");
    this.applicationCaseSec = this.getSectionByKey("anwendungsfaelle");
    this.customersSec = this.getSectionByKey("einsatz-in-der-praxis");
    this.casesStudiesSec = this.getSectionByKey("case-studies");
    this.featuresSec = this.getSectionByKey("features");
    this.resourcesSec = this.getSectionByKey("resourcen");
    this.technicalSec = this.getSectionByKey("technical");
    this.vendorSec = this.getSectionByKey("vendor");
    this.consultantActionSec = this.getSectionByKey("consultant");
  };

  get landing(): Landing {
    return this._landing;
  }
  displayedColumnsListItem: string[] = ['authority', 'military', 'industry'];
  displayedColumnsListItemTechnical: string[] = ['title', 'value']

  // define the used sections
  chemThreatSec: any;
  videoIntroSec: any;
  applicationCaseSec: any;
  customersSec: any;
  casesStudiesSec: any;
  featuresSec: any;
  resourcesSec: any;
  technicalSec: any;
  vendorSec: any;
  consultantActionSec: any;

  /**
   * 
   * @param route 
   * @param dialog 
   * @param router 
   * @param cdr 
   */
  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    public cdr: ChangeDetectorRef,
    public _sanitizer: DomSanitizer,
    public fileHelper: HelperService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit() {
    
  }
  
  ngAfterViewInit() {
    // var test = new TimelineLite();
    // const feat0 = $('.feature-0');
    // test.to(feat0, 3, {scale:1.1, ease:Power2.easeIn})
    // test.to(feat0, 2, {scale:1, ease:Power2.easeIn})

    // const feat1 = $('.feature-1');
    // test.to(feat1, 4, {scale:0.9, ease:Power2.easeIn})
    // test.to(feat1, 3, {scale:1, ease:Power2.easeIn})

    // const feat2 = $('.feature-2');
    // test.to(feat2, 3, {scale:1.1, ease:Power2.easeIn})
    // test.to(feat2, 3, {scale:1, ease:Power2.easeIn, onComplete: restartAnim })

    // function restartAnim(){
    //   test.restart();
    // }
  }

  /**
   * returns a string with 
   * leading 0 in same length
   * @param num 
   * @param places 
   * @returns 
   */
  zeroPad(num, places) {
    return String(num).padStart(places, '0');
  }

  /**
   * returns the hash as 
   * image url
   * @param hash 
   * @returns 
   */
  getImage(hash, modifier?) {
    /**
     * add default modifier to not
     * load the large images
     */
    if(!modifier) {
      modifier = {
        fit: "cover",
        width: 700,
        height: 600,
        quality: 80
      }
    }

    return this.fileHelper.getImage(hash, modifier);
  }

  getVideo(hash) {
    return this.fileHelper.getVideo(hash);
  }

  getFile(hash) {
    return this.fileHelper.getFile(hash);
  }

  introVideoUrl(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * returns the section by 
   * the given key
   * 
   * @param key 
   * @returns 
   */
  getSectionByKey(key: string) {
    if(this.landing.section.length) {
      const section = this.landing.section.filter(s => s.key === key);
      return section.length ? section[0] : false;
    }
    return false;
  }

  /**
   * maximize the image
   * to view in full size
   */
  askForProduct(product: Product) {
    const dialogRef = this.dialog.open(DialogContactByArxContent, {
      data: {
        product: product
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  /**
   * executes an action by given
   * data string
   * @param action 
   */
  actionExecute(action: string) {
    if(action === "contact") {
      this.askForProduct(this._landing.product);
    }
  }
}
