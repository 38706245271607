import { Component, OnInit, Inject } from '@angular/core';
import { Block } from '../../shared/cms/block.model';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { take, map } from 'rxjs/operators';
import { Brand } from '../../brand/brand.model';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { Product } from '../../product/product.model';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FilterPipe } from '../../shared/pipes/filter.pipe';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  brandProducts: Product[];
  currentBrand: Brand;
  headerTitle: string = '';
  headerSubtitle: string = '';
  listItemProducts: Product[];
  displayedColumnsListItem: string[] = ['title', 'desc', 'actions'];

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    const brandParam = this.route.snapshot.params['brand'];

    /**
     * fetch brand by uri param
     */
    this.store.select('brand').pipe(
      take(1),
      map(store => {
        let filtered = store.brands.filter(brand => brand.uriIdentifier === brandParam);
        if(filtered.length) {
          return filtered[0];
        }
        return null;
      })
    ).subscribe(brand => {
      this.currentBrand = brand;
      this.headerTitle = brand.title;

      // filter products by brand
      this.getFilteredProducts(brand.id);
      // get list item products
      const filterPipe = new FilterPipe();
      this.listItemProducts = filterPipe.transform(this.brandProducts, ['productListMode'], 'list_item');
    });
  }

  /**
   * fetch brand related products
   * from backend
   */
  getFilteredProducts(brandId: number) {
    this.store.select('product').pipe(
      take(1),
      map(store => {
        return store.products.filter(
          product => {
            if(product.brand !== null &&
              product.brand.id &&
              product.brand.id === brandId) {
              return true;
            }
            return false;
        });
      })
    ).subscribe(products => {
      this.brandProducts = products;
    });
  }

  /**
   * maximize the image
   * to view in full size
   */
  askForProduct(product: Product) {
    const dialogRef = this.dialog.open(DialogContactByproductContent, {
      data: {
        product: product
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  /**
   * method to open a 
   * detail page for a product
   */
  showDetails(product: Product) {
    this.router.navigate(['/product/', product.uriIdentifier])
  }

  /**
   * will open the landing page
   * @param product 
   */
  openLanding(product: Product) {
    this.router.navigate(['/special/product/', product.landing.uri]);
  }
}


@Component({
  selector: 'dialog-contact-byproduct-content',
  template: `
    <mat-dialog-content>
      <div>
        <mat-icon mat-dialog-close aria-hidden="false" aria-label="einklappen" [ngClass]="'clickable close-icon'">close</mat-icon>
        <div class="popup-contact">
          <app-contact-form [prefilledMessage]="defaultMessage"></app-contact-form>
        </div>
      </div>
    </mat-dialog-content>
  `,
})
export class DialogContactByproductContent {
  defaultMessage: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data) {
    this.defaultMessage = `Guten Tag,
ich interessiere mich für das Produkt "` + data.product.title + `".
Bitte senden Sie mir dazu folgende Informationen:
 - ...
 - ...

Vielen Dank!
    `
  }
}
