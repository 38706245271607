import { Directive, ElementRef, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as gsap from 'gsap';
import * as Swiper from 'swiper';
import Scrollbar from 'smooth-scrollbar';
import ScrollMagic from 'scrollmagic';
import {ScrollMagicPluginGsap} from 'scrollmagic-plugin-gsap';
// import 'imports-loader?define=>false!../../animate.gsap.min.js';
import 'jquery.waitforimages';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';
import { Store } from '@ngrx/store';
import * as fromAppReducer from '../../store/app.reducer';

ScrollMagicPluginGsap(ScrollMagic, gsap.TweenMax, gsap.TimelineMax);

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective implements OnChanges, OnInit, OnDestroy {
  layoutSubscription: Subscription;
  scrollbar: Scrollbar;
  scene;

  /**
   * directive to animate
   * items by fading them in
   */
  hasParallax() {
    // 	parallax image
    const controller = new ScrollMagic.Controller();
    const $this = $(this.elementRef.nativeElement);
    const $thisHeight = $this.height();
    var bg = $this.find("img");

    // Create scrollmagic scene
    this.scene = new ScrollMagic.Scene({
      triggerElement: this.elementRef.nativeElement,
      triggerHook: 1,
      duration: 20
    })
      .on("start", function (e) {
          gsap.TweenMax.to(bg, 0.2, { opacity: 1 });
      })
      .addTo(controller);
    
    if(this.scrollbar) {
      this.scrollbar.addListener(() => {
        this.scene.refresh();
      });
    }
  }

  ngOnChanges() {
    if(this.scene) {
      this.scene.refresh();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private store: Store<fromAppReducer.AppState>,
    private router: Router
  ) {
    // force directive not to reuse for route
    // if not set to false, animations stop working
    // after clicking around
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnDestroy() {
    if(this.layoutSubscription) {
      this.layoutSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.layoutSubscription = this.store.select('layout').subscribe(state => {
      if(state) {
        this.scrollbar = state.scrollbar;
        this.hasParallax();
      }
    });
  }
}
