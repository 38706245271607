import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { Product } from '../product/product.model'
import { Category } from '../category/category.model';
import { OrderItem } from './order-item.model';
import { Order } from './order.model';
import { User } from '../user/user.model';
import { ProductHelper } from '../product/product.helper';
import { AuthService } from '../auth/auth.service';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as fromApp from '../store/app.reducer';
import * as fromOrder from './store/order.reducer';
import * as OrderActions from './store/order.actions';
import * as AuthActions from '../auth/store/auth.actions';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from '../core/error-handler/notification.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  @ViewChild('stepper', { static: false }) private stepper: MatStepper;
  headerTitle: string = 'Zusammenstellung';
  headerSubtitle: string = 'EOD + Ersatzteile';
  searchForm = new UntypedFormGroup({
    searchWords: new UntypedFormControl('', [])
  });
  orderForm = new UntypedFormGroup({
    comment: new UntypedFormControl('', [])
  })
  currentUser: User;
  availableProducts: Product[];
  productsByCategories: {category: Category, products: Product[] }[];
  availableCategories: Category[] = [];
  orderItems: OrderItem[] = [];
  selectedProducts: Product[] = [];
  selectedProductsSubscription: Subscription;
  availableProductsSubscription: Subscription;
  authSubscription: Subscription;
  expanedCategories = [0];
  orderSent = false;
  orderFailures: string[];
  orderInProgress = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    public dialog: MatDialog,
    public notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.store.dispatch(new AuthActions.LoadUser())
    // load orderable products from backend
    this.availableProductsSubscription = this.store.select('product').subscribe(state => {
      this.availableProducts = state.orderableProducts;
      // group products by their categories
      this.productsByCategories = ProductHelper.sortProductsByCategories(state.orderableProducts);
    });

    // get current user
    this.authSubscription = this.store.select('auth').subscribe(state => {
      this.currentUser = state.user;


      const isSteinerAccess = this.currentUser?.tags && this.currentUser?.tags?.indexOf("steiner-access") !== -1;
      // only allow general access to order
      if(isSteinerAccess) {
        this.router.navigate(["/login"]);
      }
    })

    // observer changes in order
    this.selectedProductsSubscription = this.store.select('order').subscribe(state => {
      this.selectedProducts = state.selectedProducts;
      this.orderItems = state.preparedOrderItems;
      this.orderSent = state.orderSent;
      this.orderFailures = state.orderFailMessages;

      // control stepper to go to next step if the order has been sent
      if(this.stepper
          && this.stepper.selectedIndex == 1
          && this.orderSent
          && state.appliedOrder
          && !this.orderFailures.length) {
        this.orderInProgress = false;
        this.stepper.next();
      }

      if(this.stepper && this.stepper.selectedIndex == 1 && this.orderFailures.length && state.appliedOrder) {
        this.orderInProgress = false;
        this.notificationService.showError('Leider ist ein Fehler aufgetreten', this.orderFailures[0]);
      }

      // wait for changes in filter to expand all categories with matches
      this.searchForm.get('searchWords').valueChanges.subscribe(val => {
        this.productsByCategories.forEach((val, index) => {
          this.expandCategory(val.category.id);
        });
      });
    });
  }

  resetFilter() {
    this.searchForm.controls.searchWords.setValue('');
  }

  expandCategory(index: number) {
    if(!this.isCategoryExpanded(index)) {
      this.expanedCategories.push(index);  
    }
  }

  collapsCategory(index: number) {
    if(this.isCategoryExpanded(index)) {
      const remove = this.expanedCategories.indexOf(index);
      //delete this.expanedCategories[remove]
      this.expanedCategories.splice(remove, 1);
    }
  }

  toggleCategory(index: number) {
    if(this.isCategoryExpanded(index)) {
      this.collapsCategory(index);
    } else {
      this.expandCategory(index);
    }
  }

  isCategoryExpanded(index: number) {
    return this.expanedCategories.indexOf(index) !== -1;
  }

  /**
   * add product to order
   */
  addProduct(product, qty) {
    this.store.dispatch(new OrderActions.AddProduct({product: product, qty: qty}));
  }

  /**
   * check if product is
   * already selected for a
   * order 
   */
  isProductSelected(product) {
    return !!this.selectedProducts.find(item => item.id === product.id);
  }

  /**
   * remove product from
   * order
   */
  removeProduct(product) {
    this.store.dispatch(new OrderActions.RemoveProduct({product: product}));
  }

  /**
   * this method changes the
   * qty of the selected product
   * in the order item if exists
   */
  changeQty(product, event) {
    this.store.dispatch(new OrderActions.ChangeQtyForProduct({product: product, qty: event.target.value}));
  }

  /**
   * maximize the image
   * to view in full size
   */
  maximizeImage(product: Product) {
    const dialogRef = this.dialog.open(DialogImageContent, {
      data: {
        product: product
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  applyOrderDisabled() {
    return !this.orderItems.length || this.orderInProgress
  }

  /**
   * will send the order to
   * the backend and therefore
   * apply the order
   */
  applyOrder() {
    this.orderInProgress = true;
    let order = new Order(
      null,
      this.currentUser,
      new Date(),
      this.currentUser,
      new Date(),
      this.orderForm.controls.comment.value,
      this.orderItems
    );
    this.store.dispatch(new OrderActions.ApplyOrder(order));
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    if(this.selectedProductsSubscription) {
      this.selectedProductsSubscription.unsubscribe();
    }
    if(this.availableProductsSubscription) {
      this.availableProductsSubscription.unsubscribe();
    }
    if(this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}

@Component({
  selector: 'dialog-image-content',
  template: `
    <mat-dialog-content>
      <img mat-dialog-close src="{{data.product.mainImage}}" />
    </mat-dialog-content>
  `,
})
export class DialogImageContent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}