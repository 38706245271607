import { Component, OnInit, Input } from '@angular/core';
import { Block } from '../cms/block.model';

@Component({
  selector: 'app-unobstrusive-content',
  templateUrl: './unobstrusive-content.component.html',
  styleUrls: ['./unobstrusive-content.component.scss']
})
export class UnobstrusiveContentComponent implements OnInit {
   @Input('contentBlocks') contentBlocks: Block[];

  constructor() { }

  ngOnInit() {
  }

}
