import * as ContactFormActions from './contact-form.actions';
import { Contact } from '../contact.model';

export interface State {
  contact: Contact;
  contactInSending: boolean;
  contactSent: boolean;
  contactFailMessages: string[];
}

const initialState: State = {
  contact: null,
  contactInSending: false,
  contactSent: false,
  contactFailMessages: []
};

export function contactFormReducer(
  state: State = initialState,
  action: ContactFormActions.ContactFormActions
) {
  switch (action.type) {
    case ContactFormActions.SEND_CONTACT:
      return {
        ...state,
        contactInSending: true,
        contact: {...action.payload},
        contactSent: false
      };
    case ContactFormActions.SEND_CONTACT_WITH_FILE:
      return {
        ...state,
        contactInSending: true,
        contact: {...action.payload.contact},
        contactSent: false
      };
    case ContactFormActions.CONTACT_SENT:
      return {
        ...state,
        contactInSending: false,
        contactSent: true
      };
    case ContactFormActions.CONTACT_FAILED:
      return {
        ...state,
        contactInSending: false,
        contactFailMessages: [...action.payload]
      };
    default:
      return state;
  }
}
