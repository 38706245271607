import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { ServerErrorInterceptor } from './core/error-handler/server-error.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicErrorHandler } from './core/error-handler/basic-error.handler';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { UserModule } from './user/user.module';
import { AuthModule } from './auth/auth.module';
import { ProductgroupModule } from './productgroup/productgroup.module';
import { ProductModule } from './product/product.module';
import { BrandModule } from './brand/brand.module';
import { PagesModule } from './pages/pages.module';
import { OrderModule } from './order/order.module';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { brandReducer } from './brand/store/brand.reducer';
import { authReducer } from './auth/store/auth.reducer';
import { AuthEffects } from './auth/store/auth.effects';
import { BrandEffects } from './brand/store/brand.effects';
import { ProductgroupEffects } from './productgroup/store/productgroup.effects';
import { LayoutEffects } from './layout/store/layout.effects';
import { BlockEffects } from './shared/cms/store/block.effects';
import { NewstickerEffects } from './shared/newsticker/store/newsticker.effects';
import { ContactFormEffects } from './shared/contact-form/store/contact-form.effects';
import { ProductEffects } from './product/store/product.effects';
import { NewsletterEffects } from './shared/newsletter-subscribe/store/newsletter.effects';
import { OrderEffects } from './order/store/order.effects';
import { FileEffects } from './file/store/file.effects';
import * as fromApp from './store/app.reducer';
import { ScullyLibModule } from '@scullyio/ng-lib';

/**
 * @note this module has to be loaded last !
 */
import { PageNotFoundModule } from './core/page-not-found/page-not-found.module';
import { environment } from '../environments/environment';
import { ProductgroupComponent } from './productgroup/productgroup.component';

@NgModule({
  declarations: [AppComponent, ProductgroupComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    LayoutModule,
    PagesModule,
    AuthModule,
    UserModule,
    StoreModule.forRoot(fromApp.appReducer),
    EffectsModule.forRoot([
      AuthEffects,
      BrandEffects,
      ProductEffects,
      ContactFormEffects,
      LayoutEffects,
      NewstickerEffects,
      ProductgroupEffects,
      BlockEffects,
      OrderEffects,
      NewsletterEffects,
      FileEffects
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    BrandModule,
    ProductModule,
    ProductgroupModule,
    OrderModule,
    PageNotFoundModule,
    ScullyLibModule.forRoot({
      useTransferState: false,
      alwaysMonitor: true,
      manualIdle: true
    })
  ],
  providers: [
    { provide: ErrorHandler, useClass: BasicErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
