import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { TweenMax, Power1 } from 'gsap';

@Directive({
  selector: '[appImageTilt]'
})
export class ImageTiltDirective {
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    // keep track of mouse movements
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
  }
  mouseX: number;
  mouseY: number;
  maxTilt: number = 1.5;
  globalListenFunc: Function;

  /**
   * element will tilt by mouse
   * movement in 3d 
   * 
   * @param elementRef 
   * @param renderer 
   */
  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    this.globalListenFunc = this.renderer.listen('document', 'mousemove', e => {
      // add mouse movements to control tilt
      const width = elementRef.nativeElement.offsetWidth;
      const height = elementRef.nativeElement.offsetHeight;

      const horTilt = ((this.mouseX / width) * (this.maxTilt * 2)) - this.maxTilt;
      const verTilt = (((this.mouseY) / height) * (this.maxTilt * 2)) - this.maxTilt;
      TweenMax.to(elementRef.nativeElement, 1,{rotationY: horTilt, rotationX: verTilt, scale: 1.05, ease:Power1.easeOut});
    });
  }
}
