import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { take, map, switchMap } from 'rxjs/operators';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromApp from '../store/app.reducer';
import * as AuthActions from './store/auth.actions';
import { NotificationService } from '../core/error-handler/notification.service';

/**
 * this resolver is to protect the
 * steiner brand page and make it 
 * only available to registered users
 */
@Injectable({ providedIn: 'root' })
export class AuthBrandResolverService implements Resolve<any> {
  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
    private actions$: Actions,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /**
     * special check if the user has access to steiner
     * brand, in order to check if the 
     * user has tag "steiner-access"
     */
    if(route.params && route.params.brand === "steiner") {
      let storedUser = JSON.parse(localStorage.getItem('userData'));
      if(!storedUser) {
        this.store.dispatch(new AuthActions.LoadUser());
        return this.store.select('auth').pipe(
          take(1),
          map(authState => {
            return authState.user;
          }),
          switchMap(user => {
            if(!user || user.tags.indexOf("steiner-access") !== -1) {
              this.router.navigate(['brand-login']);
              return of(false)
            }
            else {
              return of(true)
            }
          })
        );
      } else {
        if(!storedUser || storedUser.tags.indexOf("steiner-access") === -1) {
          this.router.navigate(['brand-login']);
          return of(false)
        }
        else {
          return of(true)
        }
      }

    }
  }
}
