import * as FileActions from './file.actions';
import { File } from '../file.model';

export interface State {
  fileSending: File;
  savedFileData: any;
  uploading: boolean;
  fileUploadedSuccessfully: boolean;
  failureMessages: string[];
}

const initialState: State = {
  fileSending: null,
  savedFileData: null,
  uploading: false,
  fileUploadedSuccessfully: false,
  failureMessages: []
};

export function fileReducer(
  state: State = initialState,
  action: FileActions.FileActions
) {
  switch (action.type) {
    case FileActions.UPLOAD_FILE:
      return {
        ...state,
        uploading: true,
        fileSending: {...action.payload},
        fileUploadedSuccessfully: false
      };
    case FileActions.FILE_UPLOADED:
      return {
        ...state,
        fileSending: null,
        uploading: false,
        savedFileData: action.payload,
        fileUploadedSuccessfully: true
      };
    case FileActions.FILE_FAILED:
      return {
        ...state,
        uploading: false,
        failureMessages: [...action.payload],
        fileUploadedSuccessfully: false
      };
    default:
      return state;
  }
}
