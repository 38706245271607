import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from '../../layout/app-layout/app-layout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const pageNotFoundRoutes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: '404', pathMatch: 'full' },
      {
        path: '404',
        component: PageNotFoundComponent,
        data: { pageTitle: 'Page not found' }
      },
      { path: '**', redirectTo: '/404', pathMatch: 'full' }
    ],
    data: {
      animation: 'App',
      pageTitle: 'Page not found'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(pageNotFoundRoutes)],
  exports: [RouterModule]
})
export class PageNotFoundRoutingModule {}
