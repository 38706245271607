import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthLayoutComponent } from '../layout/auth-layout/auth-layout.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthResolverService } from './auth-resolver.service';
import { BrandProtectedLoginComponent } from './brand-protected-login/brand-protected-login.component';
import { BrandProtectedRegisterComponent } from './brand-protected-register/brand-protected-register.component';

const authRoutes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'login',
        resolve: [AuthResolverService],
        component: LoginComponent,
        data: {
          pageTitle: 'Login',
          animation: 'Login'
        }
      },
      {
        path: 'brand-login',
        resolve: [AuthResolverService],
        component: BrandProtectedLoginComponent,
        data: {
          pageTitle: 'Login',
          animation: 'Login'
        }
      },
      {
        path: 'register',
        component: RegisterComponent,
        data: {
          pageTitle: 'Register',
          animation: 'Login'
        }
      },
      {
        path: 'brand-register',
        component: BrandProtectedRegisterComponent,
        data: {
          pageTitle: 'Register',
          animation: 'Login'
        }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
          pageTitle: 'Forgot password',
          animation: 'Login'
        }
      }
    ],
    data: {
      animation: 'Auth',
      pageTitle: 'wiesel DEFENCE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
