import { User } from '../user/user.model';

export class Productgroup {
  constructor(
    public id: number,
    public status: string,
    public sort: number,
    public createdBy: User,
    public createdOn: Date,
    public name: string,
    public shortDescription: string,
    public description: string,
    public language: string,
    public image: string
  ) {}
}
