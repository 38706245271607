import { Action } from '@ngrx/store';
import { Contact } from '../contact.model';
import { File } from '../../../file/file.model';

export const SEND_CONTACT = '[Contact] SEND_CONTACT';
export const SEND_CONTACT_WITH_FILE = '[Contanct] SEND_CONTACT_WITH_FILE';
export const CONTACT_SENT = '[Contact] CONTACT_SENT';
export const CONTACT_FAILED = '[Contact] CONTACT_FAILED';

export class SendContact implements Action {
  readonly type = SEND_CONTACT;
  constructor(public payload: Contact) {}
}

export class SendContactWithFile implements Action {
  readonly type = SEND_CONTACT_WITH_FILE;
  constructor(public payload: {contact: Contact, file: File, filetype: string}) {}
}

export class ContactSent implements Action {
  readonly type = CONTACT_SENT;
}

export class ContactFailed implements Action {
  readonly type = CONTACT_FAILED;
  constructor(public payload: string[]) {}
}


export type ContactFormActions = SendContact | ContactSent | ContactFailed | SendContactWithFile;
